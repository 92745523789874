import React, { useRef } from "react";
import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Text,
  useBreakpointValue,
  VStack,
  HStack,
  Grid,
  GridItem,
  Center,
  List,
  ListItem,
  ListIcon,
  keyframes,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  Plane,
  PiggyBank,
  Globe,
  UserCheck,
  FileText,
  Users,
  Mail,
  CreditCard,
  Palette,
  HeartHandshake,
  UserPlus,
  Calendar,
  ClipboardCheck,
  BadgeCheck,
  BarChart,
  MessageCircle,
  CheckCircle2,
  LineChart,
  Cpu,
  Shield,
  Microchip,
  HandCoins,
  CalendarPlus,
  ThumbsUp,
} from "lucide-react";

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(40px); }
  to { opacity: 1; transform: translateY(0); }
`;

const ServicesPage = () => {
  const businessTravelRef = useRef(null);
  const eventsRef = useRef(null);
  const consultancyRef = useRef(null);

  const scrollToSection = (ref) => {
    const targetPosition =
      ref.current.getBoundingClientRect().top + window.pageYOffset;
    const startPosition = window.pageYOffset;
    const distance = targetPosition - startPosition;
    const duration = 1000; // Increased duration to 2 seconds
    let start = null;

    const ease = (t) => {
      // easeInOutCubic function for smooth acceleration and deceleration
      return t < 0.5 ? 4 * t * t * t : 1 - Math.pow(-2 * t + 2, 3) / 2;
    };

    const animation = (currentTime) => {
      if (start === null) start = currentTime;
      const timeElapsed = currentTime - start;
      const progress = Math.min(timeElapsed / duration, 1);

      window.scrollTo(0, startPosition + distance * ease(progress));

      if (timeElapsed < duration) {
        requestAnimationFrame(animation);
      }
    };

    requestAnimationFrame(animation);
  };

  const buttonSize = useBreakpointValue({ base: "md", md: "lg" });
  const headingSize = useBreakpointValue({ base: "2xl", md: "4xl", lg: "6xl" });
  const subHeadingSize = useBreakpointValue({
    base: "xl",
    md: "2xl",
    lg: "3xl",
  });
  const buttonDirection = useBreakpointValue({ base: "column", lg: "row" });

  const buttonStyles = {
    size: buttonSize,
    colorScheme: "blue",
    px: 8,
    py: 6,
    fontSize: { base: "md", md: "xl" },
    whiteSpace: "normal",
    height: "auto",
    bgColor: "rgba(66, 153, 225, 0.5)", // blue.500 with 0.5 opacity
    backdropFilter: "blur(8px)",
    border: "2px solid",
    borderColor: "whiteAlpha.400",
    boxShadow: "0 4px 30px rgba(0, 0, 0, 0.3)",
    cursor: "pointer",
    transition: "all 0.3s ease",
    _hover: {
      transform: "translateY(-2px)",
      boxShadow: "0 6px 40px rgba(0, 0, 0, 0.4)",
      bgColor: "rgba(66, 153, 225, 0.7)",
      borderColor: "whiteAlpha.600",
    },
    _active: {
      transform: "translateY(1px)",
      boxShadow: "0 2px 20px rgba(0, 0, 0, 0.2)",
    },
  };

  const bgColor = useColorModeValue("gray.50", "gray.900");
  const cardBg = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.700");

  const categories = [
    {
      title: "Customizable event websites",
      icon: Globe,
      items: [
        {
          text: "Registration management",
          icon: UserCheck,
          description:
            "Streamline attendee sign-ups with user-friendly registration tools.",
        },
        {
          text: "Secure payments",
          icon: CreditCard,
          description:
            "Ensure fast, safe, and reliable transactions for all participants.",
        },
        {
          text: "Tailored registration forms",
          icon: FileText,
          description:
            "Collect precise data through customized forms that match your event’s specific needs.",
        },
        {
          text: "Agenda dissemination",
          icon: Calendar,
          description:
            "Share event agendas effortlessly with participants, ensuring everyone is informed.",
        },
        {
          text: "Custom responsive design",
          icon: Palette,
          description:
            "Create visually appealing and fully responsive event websites tailored to your branding.",
        },
      ],
    },
    {
      title: "Attendee experience",
      icon: Users,
      items: [
        {
          text: "Marketing tools",
          icon: Mail,
          description:
            "Boost attendance and engagement with integrated marketing campaigns.",
        },
        {
          text: "Sponsorship management",
          icon: HeartHandshake,
          description:
            "Facilitate partnerships with streamlined sponsorship coordination.",
        },
        {
          text: "Attendee management",
          icon: UserPlus,
          description:
            "Monitor registrations, preferences, and attendance with ease.",
        },
        {
          text: "Attendee welcoming & On-Site assistance",
          icon: ClipboardCheck,
          description:
            "Ensure smooth check-ins and a welcoming atmosphere with on-the-ground support.",
        },
        {
          text: "Onsite registration & tracking",
          icon: BadgeCheck,
          description:
            "Manage on-the-spot registrations and track attendee movements in real time.",
        },
      ],
    },
    {
      title: "Actionable insights and analytics",
      icon: BarChart,
      items: [
        {
          text: "Questionnaires",
          icon: MessageCircle,
          description:
            "Gather real-time feedback and insights through interactive surveys.",
        },
        {
          text: "Customer satisfaction polls",
          icon: CheckCircle2,
          description:
            "Measure attendee satisfaction instantly to identify areas for improvement.",
        },
        {
          text: "Cost control",
          icon: CreditCard,
          description:
            "Gain visibility into expenses with detailed reporting and analysis tools.",
        },
        {
          text: "Email marketing",
          icon: Mail,
          description:
            "Stay connected with your audience before, during, and after the event.",
        },
        {
          text: "Event KPIs monitoring",
          icon: LineChart,
          description:
            "Track and analyze key performance indicators to assess the success of your event and drive continuous improvement.",
        },
      ],
    },
  ];

  return (
    <Box minH="100vh">
      {/* Hero Section */}
      <Box
        pos="relative"
        h={{ base: "400px", md: "600px" }}
        backgroundImage="url('https://travel-time.ro/images/newImages/shutterstock_2255777013.jpg')"
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        backgroundSize="cover"
      >
        {/* Dark Overlay */}
        <Box
          pos="absolute"
          top="0"
          left="0"
          w="100%"
          h="100%"
          bg="blackAlpha.100"
        />

        {/* Content */}
        <Flex
          pos="relative"
          direction="column"
          align="center"
          justify="center"
          h="100%"
          color="white"
          textAlign="center"
          px={4}
          animation={`${fadeIn} 1s ease-out`}
        >
          <Heading
            as="h1"
            color="white"
            fontSize={["4xl", "5xl", "8xl"]}
            textAlign="center"
            mb={4}
            fontFamily="'Montserrat', sans-serif"
          >
            Services
          </Heading>

          <Flex
            direction={buttonDirection}
            gap={{ base: 4, md: 6 }}
            w={{ base: "full", md: "auto" }}
            maxW="container.lg"
            justify="center"
            fontFamily="'Montserrat', sans-serif"
          >
            <Button
              {...buttonStyles}
              onClick={() => scrollToSection(businessTravelRef)}
              minW={{ lg: "400px" }}
            >
              Corporate Travel Management
            </Button>

            <Button
              {...buttonStyles}
              onClick={() => scrollToSection(eventsRef)}
              minW={{ lg: "400px" }}
            >
              Events Management
            </Button>

            <Button
              {...buttonStyles}
              onClick={() => scrollToSection(consultancyRef)}
              minW={{ lg: "400px" }}
            >
              Consultancy
            </Button>
          </Flex>
        </Flex>
      </Box>
      {/* Business Travel Section */}
      <Box ref={businessTravelRef} bg="white">
        {/* Header Image Section */}
        <Box
          position="relative"
          w="full"
          py={{ base: 8, md: 12 }}
          display="flex"
          justifyContent="center"
        >
          <Box
            position="relative"
            w={{ base: "100%", md: "75%" }}
            h={{ base: "250px", md: "500px" }}
            borderRadius={{ base: "none", md: "xl" }}
            overflow="hidden"
            boxShadow={{ base: "none", md: "xl" }}
          >
            {/* Image Container */}
            <Box
              w="full"
              h="full"
              bgColor="gray.200"
              backgroundImage="url('https://travel-time.ro/images/newImages/shutterstock_2137645247.jpg')"
              backgroundPosition="center"
              backgroundRepeat="no-repeat"
              backgroundSize="cover"
            />

            {/* Gradient Overlay for text */}
            <Box
              position="absolute"
              bottom="0"
              left="0"
              right="0"
              h="50%"
              bgGradient="linear(to-t, blackAlpha.800, blackAlpha.400, transparent)"
            />

            {/* Title Container */}
            <Center
              position="absolute"
              bottom="0"
              left="0"
              right="0"
              p={{ base: 6, md: 8 }}
              align="flex-start"
              justify="flex-start"
            >
              <Heading
                as="h2"
                size={subHeadingSize}
                color="white"
                fontFamily="Montserrat"
                textShadow="2px 2px 4px rgba(0,0,0,0.4)"
                maxW="container.lg"
              >
                Corporate Travel Management
              </Heading>
            </Center>
          </Box>
        </Box>

        <Container maxW="container.xl" py={{ base: 12, md: 20 }}>
          <Grid
            templateColumns={{ base: "1fr", lg: "repeat(2, 1fr)" }}
            gap={{ base: 8, md: 12 }}
          >
            {/* Card 1 */}
            <GridItem
              p={8}
              bg="white"
              borderRadius="xl"
              boxShadow="xl"
              transition="all 0.3s"
              _hover={{ transform: "translateY(-4px)", boxShadow: "2xl" }}
            >
              <VStack align="stretch" spacing={6}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  h="100px"
                  color="blue.500"
                >
                  <Plane size={64} strokeWidth={1.5} />
                </Box>
                <Heading
                  as="h3"
                  size="lg"
                  color="blue.600"
                  fontFamily="Montserrat"
                  textAlign="center"
                >
                  Comprehensive travel solutions
                </Heading>
                <Text
                  fontSize="md"
                  color="gray.700"
                  lineHeight="tall"
                  sx={{ fontFamily: "'Montserrat', sans-serif" }}
                >
                  At Travel Time, we go beyond simply organizing travel—we
                  deliver best in class services that ensure every corporate
                  journey is seamless, secure, and stress-free. By combining
                  access to top-tier content, advanced booking platforms, and
                  detailed analytics, we have become a trusted partner for
                  businesses transitioning to smarter travel management
                  solutions.
                </Text>
                <Text
                  fontSize="md"
                  color="gray.700"
                  lineHeight="tall"
                  sx={{ fontFamily: "'Montserrat', sans-serif" }}
                >
                  From arranging flights, hotels, and rail services to managing
                  ground transportation, passports, and visas, we handle every
                  detail with unmatched precision using a dedicated team for
                  each customer. With a foundation of expertise, deep market
                  insights, and client-focused care, our services redefine what
                  it means to manage corporate travel effectively.
                </Text>
              </VStack>
            </GridItem>

            {/* Card 2 */}
            <GridItem
              p={8}
              bg="white"
              borderRadius="xl"
              boxShadow="xl"
              transition="all 0.3s"
              _hover={{ transform: "translateY(-4px)", boxShadow: "2xl" }}
            >
              <VStack align="stretch" spacing={6}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  h="100px"
                  color="blue.500"
                >
                  <PiggyBank size={64} strokeWidth={1.5} />
                </Box>
                <Heading
                  as="h3"
                  size="lg"
                  color="blue.600"
                  fontFamily="Montserrat"
                  textAlign="center"
                >
                  Maximizing savings
                </Heading>
                <Text
                  fontSize="md"
                  color="gray.700"
                  lineHeight="tall"
                  sx={{ fontFamily: "'Montserrat', sans-serif" }}
                >
                  Travel Time employs a strategic approach to cost optimization,
                  helping clients achieve significant savings without
                  compromising on quality. Leveraging our expertise, significant
                  consolidated volumes, and our access to market relevant
                  content, we can deliver competitive rates and fares, enabling
                  businesses to reduce travel costs while enhancing traveler
                  satisfaction.
                </Text>
                <Text
                  fontSize="md"
                  color="gray.700"
                  lineHeight="tall"
                  sx={{ fontFamily: "'Montserrat', sans-serif" }}
                >
                  Our dedicated account management teams analyze spend patterns,
                  streamline pricing strategies, and tailor programs to the
                  unique needs of each client. Travel Time uses in house and
                  Amadeus robotics to streamline operations, to reprice and
                  create savings.
                </Text>
              </VStack>
            </GridItem>

            {/* Card 3 */}
            <GridItem
              p={8}
              bg="white"
              borderRadius="xl"
              boxShadow="xl"
              transition="all 0.3s"
              _hover={{ transform: "translateY(-4px)", boxShadow: "2xl" }}
            >
              <VStack align="stretch" spacing={6}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  h="100px"
                  color="blue.500"
                >
                  <Cpu size={64} strokeWidth={1.5} />
                </Box>
                <Heading
                  as="h3"
                  size="lg"
                  color="blue.600"
                  fontFamily="Montserrat"
                  textAlign="center"
                >
                  Technology-driven efficiency
                </Heading>
                <Text
                  fontSize="md"
                  color="gray.700"
                  lineHeight="tall"
                  sx={{ fontFamily: "'Montserrat', sans-serif" }}
                >
                  With cutting-edge technology, Travel Time empowers businesses
                  to track expenses in real time, monitor traveler behavior, and
                  identify trends in non-compliance. Our solutions include
                  various OBT, personalized reporting, advanced analytics, and
                  tailored strategies to boost compliance and streamline
                  operations.
                </Text>
                <Text
                  fontSize="md"
                  color="gray.700"
                  lineHeight="tall"
                  sx={{ fontFamily: "'Montserrat', sans-serif" }}
                >
                  Our robust technological infrastructure with robotics and AI
                  powered systems seamlessly integrates local, and regional
                  solutions, combining proactive account management with
                  flexible tools that adapt to your organization’s unique travel
                  requirements.
                </Text>
              </VStack>
            </GridItem>

            {/* Card 4 */}
            <GridItem
              p={8}
              bg="white"
              borderRadius="xl"
              boxShadow="xl"
              transition="all 0.3s"
              _hover={{ transform: "translateY(-4px)", boxShadow: "2xl" }}
            >
              <VStack align="stretch" spacing={6}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  h="100px"
                  color="blue.500"
                >
                  <Shield size={64} strokeWidth={1.5} />
                </Box>
                <Heading
                  as="h3"
                  size="lg"
                  color="blue.600"
                  fontFamily="Montserrat"
                  textAlign="center"
                >
                  Comprehensive 24/7 Traveler support for emergency
                </Heading>
                <Text
                  fontSize="md"
                  color="gray.700"
                  lineHeight="tall"
                  sx={{ fontFamily: "'Montserrat', sans-serif" }}
                >
                  At Travel Time, support, traveler safety and satisfaction are
                  at the core of everything we do. Our dedicated duty of care
                  solutions leverage advanced tools to pinpoint traveler
                  locations in real time, proactively manage disruptions, and
                  deliver swift responses to emergencies.
                </Text>
                <Text
                  fontSize="md"
                  color="gray.700"
                  lineHeight="tall"
                  sx={{ fontFamily: "'Montserrat', sans-serif" }}
                >
                  With robust 24/7 support services, well-documented contingency
                  plans, and efficient complaint-handling procedures, Travel
                  Time offers one of the most reliable and responsive live
                  support systems in the industry, ensuring peace of mind for
                  every traveler.
                </Text>
              </VStack>
            </GridItem>
          </Grid>
        </Container>
      </Box>
      {/* Events Management Section */}
      <Box ref={eventsRef} bg="white">
        {/* Header Image Section */}
        <Box
          position="relative"
          w="full"
          py={{ base: 8, md: 12 }}
          display="flex"
          justifyContent="center"
        >
          <Box
            position="relative"
            w={{ base: "100%", md: "75%" }}
            h={{ base: "250px", md: "500px" }}
            borderRadius={{ base: "none", md: "xl" }}
            overflow="hidden"
            boxShadow={{ base: "none", md: "xl" }}
          >
            {/* Image Container */}
            <Box
              w="full"
              h="full"
              bgColor="gray.200"
              backgroundImage="url('https://travel-time.ro/images/newImages/shutterstock_2485923623.jpg')"
              backgroundPosition="center"
              backgroundRepeat="no-repeat"
              backgroundSize="cover"
            />

            {/* Gradient Overlay for text */}
            <Box
              position="absolute"
              bottom="0"
              left="0"
              right="0"
              h="50%"
              bgGradient="linear(to-t, blackAlpha.800, blackAlpha.400, transparent)"
            />

            {/* Title Container */}
            <Center
              position="absolute"
              bottom="0"
              left="0"
              right="0"
              p={{ base: 6, md: 8 }}
              align="flex-start"
              justify="flex-start"
            >
              <Heading
                as="h2"
                size={subHeadingSize}
                color="white"
                fontFamily="Montserrat"
                textShadow="2px 2px 4px rgba(0,0,0,0.4)"
                maxW="container.lg"
              >
                Events Management
              </Heading>
            </Center>
          </Box>
        </Box>

        <Container maxW="container.xl" py={{ base: 12, md: 20 }}>
          <Grid
            templateColumns={{ base: "1fr", lg: "repeat(2, 1fr)" }}
            gap={{ base: 8, md: 12 }}
          >
            {/* Card 1 */}
            <GridItem
              p={8}
              bg="white"
              borderRadius="xl"
              boxShadow="xl"
              transition="all 0.3s"
              _hover={{ transform: "translateY(-4px)", boxShadow: "2xl" }}
            >
              <VStack align="stretch" spacing={6}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  h="100px"
                  color="blue.500"
                >
                  <Microchip size={64} strokeWidth={1.5} />
                </Box>
                <Heading
                  as="h3"
                  size="lg"
                  color="blue.600"
                  fontFamily="Montserrat"
                  textAlign="center"
                >
                  The Process
                </Heading>
                <Text
                  fontSize="md"
                  color="gray.700"
                  lineHeight="tall"
                  sx={{ fontFamily: "'Montserrat', sans-serif" }}
                >
                  A dedicated Senior Project Manager oversees the entire
                  process, including venue selection, event road map and budget
                  control, to deliver outstanding results. With successful
                  events ranging from 6 participants to 300—including small
                  meetings, product launches, teambuildings and incentive
                  solutions — Travel Time offers expert planning,
                  implementation, reporting and management of the full range of
                  corporate events.
                </Text>
              </VStack>
            </GridItem>

            {/* Card 2 */}
            <GridItem
              p={8}
              bg="white"
              borderRadius="xl"
              boxShadow="xl"
              transition="all 0.3s"
              _hover={{ transform: "translateY(-4px)", boxShadow: "2xl" }}
            >
              <VStack align="stretch" spacing={6}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  h="100px"
                  color="blue.500"
                >
                  <CalendarPlus size={64} strokeWidth={1.5} />
                </Box>
                <Heading
                  as="h3"
                  size="lg"
                  color="blue.600"
                  fontFamily="Montserrat"
                  textAlign="center"
                >
                  Road map
                </Heading>
                <Text
                  fontSize="md"
                  color="gray.700"
                  lineHeight="tall"
                  sx={{ fontFamily: "'Montserrat', sans-serif" }}
                >
                  Our services cover every aspect of event logistics,
                  consolidating and managing travel and event-related steps with
                  transparency and multiple options. This approach generates
                  significant savings for MICE programs while ensuring unmatched
                  value and cost efficiency.
                </Text>
              </VStack>
            </GridItem>

            {/* Card 3 */}
            <GridItem
              p={8}
              bg="white"
              borderRadius="xl"
              boxShadow="xl"
              transition="all 0.3s"
              _hover={{ transform: "translateY(-4px)", boxShadow: "2xl" }}
            >
              <VStack align="stretch" spacing={6}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  h="100px"
                  color="blue.500"
                >
                  <HandCoins size={64} strokeWidth={1.5} />
                </Box>
                <Heading
                  as="h3"
                  size="lg"
                  color="blue.600"
                  fontFamily="Montserrat"
                  textAlign="center"
                >
                  Budget
                </Heading>
                <Text
                  fontSize="md"
                  color="gray.700"
                  lineHeight="tall"
                  sx={{ fontFamily: "'Montserrat', sans-serif" }}
                >
                  We recommend only trusted venues and ensure full visibility of
                  budget along every step. Data analysis, reporting and market
                  trends will enable Travel Time to balance cost/benefit
                  according to customer objectives. Our scalable solutions,
                  combined with a creative and consultative approach, give
                  clients control over costs while maintaining quality.
                </Text>
              </VStack>
            </GridItem>

            {/* Card 4 */}
            <GridItem
              p={8}
              bg="white"
              borderRadius="xl"
              boxShadow="xl"
              transition="all 0.3s"
              _hover={{ transform: "translateY(-4px)", boxShadow: "2xl" }}
            >
              <VStack align="stretch" spacing={6}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  h="100px"
                  color="blue.500"
                >
                  <ThumbsUp size={64} strokeWidth={1.5} />
                </Box>
                <Heading
                  as="h3"
                  size="lg"
                  color="blue.600"
                  fontFamily="Montserrat"
                  textAlign="center"
                >
                  Event Features: Comprehensive solutions for seamless
                  experiences
                </Heading>
                <Text
                  fontSize="md"
                  color="gray.700"
                  lineHeight="tall"
                  sx={{ fontFamily: "'Montserrat', sans-serif" }}
                >
                  At Travel Time, we deliver end-to-end event management
                  solutions that combine cutting-edge technology with expert
                  planning to ensure every detail is executed flawlessly. Our
                  feature-rich services are designed to streamline the event
                  process, enhance attendee engagement, and provide actionable
                  insights for measurable success.
                </Text>
              </VStack>
            </GridItem>
          </Grid>
        </Container>
      </Box>
      <Box py={{ base: 12, md: 20 }} bg={bgColor}>
        <Container maxW="container.xl">
          <Grid
            templateColumns={{ base: "1fr", lg: "repeat(3, 1fr)" }}
            gap={{ base: 8, lg: 6 }}
          >
            {categories.map((category, idx) => (
              <GridItem
                key={idx}
                bg={cardBg}
                p={8}
                borderRadius="xl"
                borderWidth="1px"
                borderColor={borderColor}
                boxShadow="xl"
                transition="all 0.3s"
                _hover={{ transform: "translateY(-4px)", boxShadow: "2xl" }}
              >
                <VStack spacing={6} align="start">
                  <Box
                    w="full"
                    display="flex"
                    alignItems="center"
                    gap={3}
                    pb={4}
                    borderBottomWidth="2px"
                    borderColor="blue.500"
                  >
                    <category.icon size={28} color="#3182CE" />
                    <Heading
                      as="h3"
                      size="md"
                      color="blue.600"
                      fontFamily="Montserrat"
                    >
                      {category.title}
                    </Heading>
                  </Box>

                  <List spacing={6} w="full">
                    {category.items.map((item, itemIdx) => (
                      <ListItem
                        key={itemIdx}
                        display="flex"
                        alignItems="flex-start"
                        gap={3}
                      >
                        <ListIcon
                          as={item.icon}
                          color="blue.500"
                          mt={1}
                          boxSize={5}
                        />
                        <Box>
                          <Text
                            fontWeight="600"
                            color="gray.700"
                            fontSize="md"
                            mb={1}
                          >
                            {item.text}
                          </Text>
                          <Text
                            color="gray.600"
                            fontSize="sm"
                            lineHeight="tall"
                            fontFamily="Montserrat"
                          >
                            {item.description}
                          </Text>
                        </Box>
                      </ListItem>
                    ))}
                  </List>
                </VStack>
              </GridItem>
            ))}
          </Grid>
        </Container>
      </Box>
      {/* Business Travel Section */}
      <Box ref={consultancyRef} bg="white">
        {/* Header Image Section */}
        <Box
          position="relative"
          w="full"
          py={{ base: 8, md: 12 }}
          display="flex"
          justifyContent="center"
        >
          <Box
            position="relative"
            w={{ base: "100%", md: "75%" }}
            h={{ base: "250px", md: "500px" }}
            borderRadius={{ base: "none", md: "xl" }}
            overflow="hidden"
            boxShadow={{ base: "none", md: "xl" }}
          >
            {/* Image Container */}
            <Box
              w="full"
              h="full"
              bgColor="gray.200"
              backgroundImage="url('https://travel-time.ro/images/newImages/shutterstock_670699621.jpg')"
              backgroundPosition="center"
              backgroundRepeat="no-repeat"
              backgroundSize="cover"
            />

            {/* Gradient Overlay for text */}
            <Box
              position="absolute"
              bottom="0"
              left="0"
              right="0"
              h="50%"
              bgGradient="linear(to-t, blackAlpha.800, blackAlpha.400, transparent)"
            />

            {/* Title Container */}
            <Center
              position="absolute"
              bottom="0"
              left="0"
              right="0"
              p={{ base: 6, md: 8 }}
              align="flex-start"
              justify="flex-start"
            >
              <Heading
                as="h2"
                size={subHeadingSize}
                color="white"
                fontFamily="Montserrat"
                textShadow="2px 2px 4px rgba(0,0,0,0.4)"
                maxW="container.lg"
              >
                Consultancy
              </Heading>
            </Center>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ServicesPage;
