// import React from "react";
// import { useRef, useState } from "react";
// import emailjs from "@emailjs/browser";
// import { useNavigate } from "react-router-dom";
// import {
//   FormControl,
//   Divider,
//   Textarea,
//   FormHelperText,
//   Input,
//   Button,
//   Heading,
//   Box,
//   useToast,
//   useBreakpointValue,
// } from "@chakra-ui/react";
// import ReactGA from "react-ga4";

// const FormContact2222222 = () => {
//   const form = useRef();
//   const [isPending, setIsPending] = useState(false);
//   const history = useNavigate();
//   const toast = useToast();
//   const showToast = () => {
//     toast({
//       position: "top",
//       title: "Mesaj Trimis",
//       description: "Mesaj trimis cu succes",
//       duration: 5000,
//       isClosable: true,
//     });
//   };

//   const sendEmail = (e) => {
//     e.preventDefault();

//     setIsPending(true);

//     emailjs
//       .sendForm(
//         "service_4vhsjzg",
//         "template_u9vdbgs",
//         form.current,
//         "cvwaMScxKKMXrSpbl"
//       )
//       .then((result) => {
//         console.log(result.text);
//         setIsPending(false);

//         // Adăugați codul de urmărire a conversiei aici
//         ReactGA.send({
//           hitType: "event",
//           eventCategory: "Formular de contact",
//           eventAction: "Trimitere mesaj",
//           eventLabel: "Conversie",
//         });
//         history("/");
//         showToast();
//       });
//   };

//   const headingSize = useBreakpointValue({ base: "md", md: "2xl" });
//   const marginBottom = useBreakpointValue({ base: "6px", md: "10px" });
//   const widthBox = useBreakpointValue({ base: "75%", md: "50%" });
//   const fontSize = useBreakpointValue({ base: "35px", md: "60px" });

//   return (
//     <>
//       <Box className="mx-auto" width={widthBox} mt="10px">
//         <Heading
//           align="center"
//           color="blue.400"
//           as="h1"
//           size={headingSize}
//           mb={marginBottom}
//           sx={{
//             WebkitTextStrokeWidth: "1px",
//             WebkitTextFillColor: "#fff",
//             WebkitTextStrokeColor: "#000",
//             fontSize: fontSize,
//             lineHeight: "33px",
//             fontFamily: "Mark-Pro-Bold",
//             color: "#000f40",
//             textAlign: "center",
//             marginBottom: "20px",
//           }}
//         >
//           Contacteaza-ne!
//         </Heading>

//         <form ref={form} onSubmit={sendEmail}>
//           <FormControl mb="10px">
//             <Input type="text" name="user_name" placeholder="Nume" required />
//           </FormControl>
//           <FormControl mb="10px">
//             <Input
//               type="email"
//               name="user_email"
//               placeholder="example@gmail.com"
//               required
//             />
//           </FormControl>
//           <FormControl mb="10px">
//             <Input
//               type="number"
//               name="mobile_number"
//               placeholder="Numar Telefon"
//               required
//             />
//           </FormControl>

//           <FormControl>
//             <FormHelperText mb="5px">Solicitarea dumneavoastra </FormHelperText>
//             <Textarea
//               mb="10px"
//               name="message"
//               placeholder="Mesaj"
//               rows="4"
//               required
//             />
//           </FormControl>

//           {!isPending && (
//             <Button colorScheme="teal" align="center" type="submit">
//               Trimite Mesaj
//             </Button>
//           )}
//           {isPending && (
//             <Button align="center" type="submit">
//               Se trimite...
//             </Button>
//           )}
//         </form>
//       </Box>

//       <Divider mt="30px" />
//     </>
//   );
// };

// export default FormContact2222222;

import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { useNavigate } from "react-router-dom";
import {
  FormControl,
  Divider,
  Textarea,
  FormHelperText,
  Input,
  Button,
  Heading,
  Box,
  useToast,
  useBreakpointValue,
  VStack,
  Container,
  Text,
  InputGroup,
  InputLeftElement,
  Icon,
  Card,
  CardBody,
  useColorModeValue,
} from "@chakra-ui/react";
import { FaUser, FaEnvelope, FaPhone, FaPaperPlane } from "react-icons/fa";
import ReactGA from "react-ga4";

const FormContact2222222 = () => {
  const form = useRef();
  const [isPending, setIsPending] = useState(false);
  const history = useNavigate();
  const toast = useToast();

  const showToast = () => {
    toast({
      position: "top",
      title: "Mesaj Trimis",
      description: "Mesaj trimis cu succes",
      status: "success",
      duration: 5000,
      isClosable: true,
    });
  };

  const sendEmail = (e) => {
    e.preventDefault();
    setIsPending(true);

    emailjs
      .sendForm(
        "service_4vhsjzg",
        "template_u9vdbgs",
        form.current,
        "cvwaMScxKKMXrSpbl"
      )
      .then((result) => {
        console.log(result.text);
        setIsPending(false);

        ReactGA.send({
          hitType: "event",
          eventCategory: "Formular de contact",
          eventAction: "Trimitere mesaj",
          eventLabel: "Conversie",
        });

        history("/");
        showToast();
      });
  };

  // Responsive values
  const containerWidth = useBreakpointValue({
    base: "90%",
    md: "70%",
    lg: "50%",
  });
  const headingSize = useBreakpointValue({ base: "xl", md: "2xl" });

  // Color mode values
  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.600");
  const headingGradient = useColorModeValue(
    "linear(to-r, blue.400, teal.400)",
    "linear(to-r, blue.200, teal.200)"
  );

  return (
    <Container maxW={containerWidth} py={8}>
      <VStack spacing={8}>
        <Heading
          bgGradient={headingGradient}
          bgClip="text"
          size={headingSize}
          textAlign="center"
          letterSpacing="tight"
        >
          Contact Us!
        </Heading>

        <Card
          w="full"
          boxShadow="xl"
          bg={bgColor}
          borderWidth="1px"
          borderColor={borderColor}
          borderRadius="xl"
        >
          <CardBody>
            <form ref={form} onSubmit={sendEmail}>
              <VStack spacing={4}>
                <FormControl>
                  <InputGroup>
                    <InputLeftElement>
                      <Icon as={FaUser} color="gray.500" />
                    </InputLeftElement>
                    <Input
                      type="text"
                      name="user_name"
                      placeholder="Name"
                      required
                      borderRadius="lg"
                      _hover={{ borderColor: "blue.400" }}
                      _focus={{ borderColor: "blue.400", boxShadow: "outline" }}
                    />
                  </InputGroup>
                </FormControl>

                <FormControl>
                  <InputGroup>
                    <InputLeftElement>
                      <Icon as={FaEnvelope} color="gray.500" />
                    </InputLeftElement>
                    <Input
                      type="email"
                      name="user_email"
                      placeholder="example@gmail.com"
                      required
                      borderRadius="lg"
                      _hover={{ borderColor: "blue.400" }}
                      _focus={{ borderColor: "blue.400", boxShadow: "outline" }}
                    />
                  </InputGroup>
                </FormControl>

                <FormControl>
                  <InputGroup>
                    <InputLeftElement>
                      <Icon as={FaPhone} color="gray.500" />
                    </InputLeftElement>
                    <Input
                      type="number"
                      name="mobile_number"
                      placeholder="Phone Number"
                      required
                      borderRadius="lg"
                      _hover={{ borderColor: "blue.400" }}
                      _focus={{ borderColor: "blue.400", boxShadow: "outline" }}
                    />
                  </InputGroup>
                </FormControl>

                <FormControl>
                  <FormHelperText mb={2} fontSize="sm" color="gray.600">
                    Solicitarea dumneavoastra
                  </FormHelperText>
                  <Textarea
                    name="message"
                    placeholder="Message"
                    rows={4}
                    required
                    borderRadius="lg"
                    _hover={{ borderColor: "blue.400" }}
                    _focus={{ borderColor: "blue.400", boxShadow: "outline" }}
                    resize="vertical"
                  />
                </FormControl>

                <Button
                  type="submit"
                  w="full"
                  size="lg"
                  colorScheme="blue"
                  borderRadius="lg"
                  isLoading={isPending}
                  loadingText="Sending..."
                  leftIcon={<FaPaperPlane />}
                  _hover={{
                    transform: "translateY(-2px)",
                    boxShadow: "lg",
                  }}
                  transition="all 0.2s"
                >
                  {isPending ? "Sending..." : "Send Message"}
                </Button>
              </VStack>
            </form>
          </CardBody>
        </Card>
      </VStack>

      <Divider mt={8} />
    </Container>
  );
};

export default FormContact2222222;
