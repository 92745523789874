import React from "react";
import {
  Box,
  Container,
  Heading,
  Text,
  Grid,
  GridItem,
  VStack,
  HStack,
  Circle,
  useColorModeValue,
} from "@chakra-ui/react";
import { Award, Cpu, LineChart, Users, Phone } from "lucide-react";

const BenefitsSection = () => {
  const bgColor = useColorModeValue("gray.50", "gray.900");
  const cardBg = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.700");

  const benefits = [
    {
      icon: Award,
      title: "Expertise",
      description:
        "A team of seasoned professionals offering insights and solutions tailored to your travel program.",
      color: "blue.400",
    },
    {
      icon: Cpu,
      title: "Technology",
      description:
        "Advanced tools for booking, expense tracking, and reporting, ensuring efficiency at every stage.",
      color: "purple.400",
    },
    {
      icon: LineChart,
      title: "Transparency",
      description:
        "Tech-driven ERP systems and reporting mechanisms for full visibility into your travel spend.",
      color: "green.400",
    },
    {
      icon: Users,
      title: "Dedicated Support",
      description:
        "Personalized attention from a dedicated team that understands your unique needs.",
      color: "orange.400",
    },
    {
      icon: Phone,
      title: "24/7 Assistance",
      description:
        "Around-the-clock support to handle disruptions, emergencies, and traveler concerns.",
      color: "red.400",
    },
  ];

  return (
    <Box py={{ base: 12, md: 20 }} bg={bgColor}>
      <Container maxW="container.xl">
        <VStack spacing={12}>
          {/* Header Section */}
          <Box textAlign="center" maxW="800px">
            <Heading
              as="h2"
              size="xl"
              mb={4}
              color="blue.600"
              fontFamily="Montserrat"
            >
              Customer benefits
            </Heading>
            <Text fontSize="lg" color="gray.600">
              Discover the advantages of partnering with a dedicated team of
              travel experts
            </Text>
          </Box>

          {/* Benefits Grid */}
          <Grid
            templateColumns={{
              base: "1fr",
              md: "repeat(2, 1fr)",
              lg: "repeat(3, 1fr)",
            }}
            gap={8}
            w="full"
          >
            {benefits.map((benefit, idx) => (
              <GridItem key={idx}>
                <Box
                  h="full"
                  bg={cardBg}
                  p={8}
                  borderRadius="xl"
                  borderWidth="1px"
                  borderColor={borderColor}
                  position="relative"
                  transition="all 0.3s"
                  _hover={{
                    transform: "translateY(-4px)",
                    boxShadow: "xl",
                    borderColor: benefit.color,
                  }}
                >
                  <VStack spacing={6} align="start">
                    <Circle
                      size="50px"
                      bg={benefit.color}
                      color="white"
                      transform="rotate(-10deg)"
                      transition="transform 0.3s"
                      _groupHover={{ transform: "rotate(0deg)" }}
                    >
                      <benefit.icon size={24} />
                    </Circle>

                    <VStack align="start" spacing={3}>
                      <Heading
                        as="h3"
                        size="md"
                        color="gray.800"
                        fontFamily="Montserrat"
                      >
                        {benefit.title}
                      </Heading>
                      <Text color="gray.600" fontSize="md" lineHeight="tall">
                        {benefit.description}
                      </Text>
                    </VStack>
                  </VStack>

                  {/* Decorative corner accent */}
                  <Box
                    position="absolute"
                    top="-1px"
                    right="-1px"
                    w="40px"
                    h="40px"
                    bg={benefit.color}
                    opacity={0.1}
                    borderTopRightRadius="xl"
                  />
                </Box>
              </GridItem>
            ))}
          </Grid>

          {/* Bottom Content Box */}
          <Box
            w="full"
            p={8}
            bg="blue.500"
            color="white"
            borderRadius="xl"
            textAlign="center"
            position="relative"
            overflow="hidden"
          >
            <Box
              position="absolute"
              top="-20px"
              right="-20px"
              opacity={0.1}
              transform="rotate(45deg)"
            >
              <Award size={150} />
            </Box>
            <VStack spacing={4} position="relative">
              <Heading size="lg" fontFamily="Montserrat">
                Experience & Innovation
              </Heading>
              <Text fontSize="lg" maxW="800px">
                We combine extensive expertise with modern technology to deliver
                top-level travel services.
              </Text>
            </VStack>
          </Box>
        </VStack>
      </Container>
    </Box>
  );
};

export default BenefitsSection;
