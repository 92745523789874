import React from "react";
import {
  Box,
  Container,
  Heading,
  Text,
  Grid,
  GridItem,
  VStack,
  HStack,
  Flex,
  Circle,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  Globe,
  LineChart,
  CreditCard,
  Smartphone,
  Cpu,
  ArrowUpRight,
  Hotel,
  Car,
  Train,
  Plane,
  PieChart,
  Wallet,
  Bell,
  Bot,
} from "lucide-react";

const TechSolutionsSection = () => {
  const bgColor = useColorModeValue("white", "gray.900");
  const cardBg = useColorModeValue("white", "gray.800");
  const accentBg = useColorModeValue("blue.50", "blue.900");

  const solutions = [
    {
      title: "Online Booking solutions",
      icon: Globe,
      description:
        "Travel Time’s multiple booking solutions provide access to the best global fares, empowering businesses to maximize savings on every trip. Whether it’s flights, hotels, car rentals, or rail services, our tools enable seamless bookings to virtually any destination and departure point. By understanding clients preferences and company policies, we suggest the best OBT solution. We ensure a tailored approach that aligns with your objectives while optimizing cost efficency.",
      features: [
        { icon: Plane, text: "Flights" },
        { icon: Hotel, text: "Hotels" },
        { icon: Car, text: "Car Rentals" },
        { icon: Train, text: "Rail Services" },
      ],
    },
    {
      title: "Comprehensive data insights",
      icon: LineChart,
      description:
        "Gain a clear picture of your travel performance with our advanced data analysis tools. We collect, process, and present key metrics in detailed reports, offering an in-depth overview of spending trends, compliance levels, and cost-saving opportunities.From granular details to high-level insights, our reporting system equips travel managers with the knowledge they need to streamline operations, refine strategies, and achieve measurable improvements.",
      features: [
        { icon: PieChart, text: "Detailed Reports" },
        { icon: LineChart, text: "Spending Trends" },
        { icon: Bot, text: "Automated Analysis" },
        { icon: ArrowUpRight, text: "Continuous Optimization" },
      ],
    },
    {
      title: "Effortless expense tracking",
      icon: CreditCard,
      description:
        "Keeping track of travel expenses has never been easier. Our technology provides real-time expense monitoring, offering a transparent view of how funds are allocated across your travel program.Beyond numbers, we analyze traveler and arranger behavior to identify compliance gaps, detect trends in policy adherence, and highlight opportunities to optimize budget utilization.",
      features: [
        { icon: Wallet, text: "Real-Time Monitoring" },
        { icon: LineChart, text: "Behavior Analysis" },
        { icon: PieChart, text: "Detailed Reports" },
        { icon: Bot, text: "Trend Detection" },
      ],
    },
    {
      title: "Mobility and real-time support",
      icon: Smartphone,
      description:
        "Designed for modern travelers, our intuitive mobile solutions brings convenience and control to your fingertips. Manage itineraries on the go, receive instant notifications for schedule updates, and access a comprehensive duty of care dashboard.For program managers, the solutions offers powerful tools to track traveler locations in real-time, anticipate disruptions, and provide proactive support when it matters most.",
      features: [
        { icon: Bell, text: "Instant Notifications" },
        { icon: Globe, text: "Real-Time Location" },
        { icon: Bot, text: "Proactive Support" },
        { icon: LineChart, text: "Complete Dashboard" },
      ],
    },
    {
      title: "Automated efficiency",
      icon: Cpu,
      description:
        "At Travel Time, we harness the power of automation to deliver unparalleled efficiency and savings. Our Amadeus Robots streamline booking processes, while our proprietary Repricer Tool continuously searches for better rates, reissuing tickets at reduced costs whenever possible.This combination of cutting-edge automation and human expertise ensures your travel program remains agile, cost-effective, and aligned with your business goals.",
      features: [
        { icon: Bot, text: "Amadeus Bots" },
        { icon: ArrowUpRight, text: "Automated Repricing" },
        { icon: LineChart, text: "Automated Reporting" },
        { icon: CreditCard, text: "Automated Payments" },
      ],
    },
  ];

  return (
    <Box py={{ base: 12, md: 20 }} bg={bgColor}>
      <Container maxW="container.xl">
        <VStack spacing={16}>
          {/* Header */}
          <Box textAlign="center" maxW="800px">
            <Heading
              as="h2"
              size="2xl"
              mb={4}
              color="blue.600"
              fontFamily="Montserrat"
            >
              Innovative travel technology solutions
            </Heading>
            <Text fontSize="xl" color="gray.600" fontFamily="Montserrat">
              Advanced technology for an optimized travel experience
            </Text>
          </Box>

          {/* Solutions Grid */}
          <Grid templateColumns={{ base: "1fr", lg: "repeat(2, 1fr)" }} gap={8}>
            {solutions.map((solution, idx) => (
              <GridItem key={idx}>
                <Box
                  h="full"
                  bg={cardBg}
                  p={8}
                  borderRadius="xl"
                  boxShadow="xl"
                  position="relative"
                  overflow="hidden"
                  transition="all 0.3s"
                  _hover={{
                    transform: "translateY(-4px)",
                    boxShadow: "2xl",
                  }}
                >
                  {/* Solution Header */}
                  <HStack spacing={4} mb={6}>
                    <Circle size="48px" bg="blue.500" color="white">
                      <solution.icon size={24} />
                    </Circle>
                    <Heading
                      as="h3"
                      size="lg"
                      fontFamily="Montserrat"
                      color="blue.700"
                    >
                      {solution.title}
                    </Heading>
                  </HStack>

                  {/* Description */}
                  <Text
                    color="gray.600"
                    mb={6}
                    fontSize="lg"
                    lineHeight="tall"
                    fontFamily="Montserrat"
                  >
                    {solution.description}
                  </Text>

                  {/* Features Grid */}
                  <Grid
                    templateColumns="repeat(2, 1fr)"
                    gap={4}
                    bg={accentBg}
                    p={4}
                    borderRadius="lg"
                  >
                    {solution.features.map((feature, featureIdx) => (
                      <HStack
                        key={featureIdx}
                        spacing={3}
                        p={2}
                        transition="all 0.2s"
                        _hover={{
                          transform: "translateX(4px)",
                        }}
                      >
                        <feature.icon size={18} color="#3182CE" />
                        <Text
                          fontSize="md"
                          fontWeight="medium"
                          color="gray.700"
                          fontFamily="Montserrat"
                        >
                          {feature.text}
                        </Text>
                      </HStack>
                    ))}
                  </Grid>

                  {/* Background Decoration */}
                  <Box
                    position="absolute"
                    right="-20"
                    bottom="-20"
                    opacity={0.03}
                  >
                    <solution.icon size={150} />
                  </Box>
                </Box>
              </GridItem>
            ))}
          </Grid>

          {/* Bottom Banner */}
          <Box
            w="full"
            p={8}
            bg="blue.500"
            color="white"
            borderRadius="xl"
            position="relative"
            overflow="hidden"
          >
            <Box position="absolute" right="-10" top="-10" opacity={0.1}>
              <Cpu size={200} />
            </Box>
            <VStack
              spacing={4}
              position="relative"
              align="center"
              maxW="800px"
              mx="auto"
            >
              <Heading size="lg" textAlign="center" fontFamily="Montserrat">
                Technology and Expertise United
              </Heading>
              <Text fontSize="lg" textAlign="center" fontFamily="Montserrat">
                We combine the most advanced technological solutions with human
                expertise to deliver exceptional travel services.
              </Text>
            </VStack>
          </Box>
        </VStack>
      </Container>
    </Box>
  );
};

export default TechSolutionsSection;
