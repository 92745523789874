// // import React from "react";
// // import { Link } from "react-router-dom";
// // import { Button } from "@chakra-ui/react";
// // import "./b2b-header.css";

// // const B2bHeader = () => {
// //   return (
// //     <header>
// //       <div
// //         className="p-5 text-center bg-image mb-3"
// //         style={{
// //           backgroundImage:
// //             "url('https://www.dcsplus.net/hubfs/banner_IRIX_1903x524_v2c.jpg')",
// //           height: "400px",
// //         }}
// //       >
// //         <div className="mask" style={{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}>
// //           <div className="d-flex justify-content-center align-items-center h-100">
// //             <div className="text-white">
// //               <h1 className="mb-3 title-heading" style={{ fontSize: "34px" }}>
// //                 BOOKING TIME
// //               </h1>
// //               <Link to="/contact">
// //                 <Button
// //                   as="a"
// //                   variant="outline"
// //                   target="_blank"
// //                   size="lg"
// //                   colorScheme="teal"
// //                   _hover={{ color: "white" }}
// //                 >
// //                   Incearca acum!
// //                 </Button>
// //               </Link>

// //               <Button
// //                 href="https://tbs.flytime.ro/reseller/auth/"
// //                 colorScheme="teal"
// //                 as="a"
// //                 size="lg"
// //                 variant="outline"
// //                 marginLeft="10px"
// //                 _hover={{ color: "white" }}
// //               >
// //                 Login
// //               </Button>
// //             </div>
// //           </div>
// //         </div>
// //       </div>
// //     </header>
// //   );
// // };

// // export default B2bHeader;

// import React from "react";
// import { Link } from "react-router-dom";
// import {
//   Box,
//   Button,
//   Container,
//   Heading,
//   Stack,
//   Text,
//   useColorModeValue,
//   keyframes,
//   IconButton,
// } from "@chakra-ui/react";
// import { ArrowRight, LogIn } from "lucide-react";

// const fadeIn = keyframes`
//   from { opacity: 0; transform: translateY(20px); }
//   to { opacity: 1; transform: translateY(0); }
// `;

// const B2bHeader = () => {
//   const overlayBg = useColorModeValue(
//     "rgba(0, 0, 0, 0.5)",
//     "rgba(0, 0, 0, 0.7)"
//   );

//   const buttonHoverBg = useColorModeValue("white", "gray.700");
//   const buttonHoverColor = useColorModeValue("blue.600", "white");

//   return (
//     <Box
//       as="header"
//       position="relative"
//       height="500px"
//       width="100%"
//       overflow="hidden"
//     >
//       {/* Background Image */}
//       <Box
//         position="absolute"
//         top={0}
//         left={0}
//         right={0}
//         bottom={0}
//         backgroundImage="url('https://www.dcsplus.net/hubfs/banner_IRIX_1903x524_v2c.jpg')"
//         backgroundSize="cover"
//         backgroundPosition="center"
//         backgroundRepeat="no-repeat"
//         filter="brightness(0.9)"
//         transition="transform 0.3s ease-in-out"
//         _after={{
//           content: '""',
//           position: "absolute",
//           top: 0,
//           left: 0,
//           right: 0,
//           bottom: 0,
//           bg: overlayBg,
//           zIndex: 1,
//         }}
//       />

//       {/* Content */}
//       <Container maxW="7xl" height="100%" position="relative" zIndex={2}>
//         <Stack
//           height="100%"
//           spacing={6}
//           alignItems="center"
//           justifyContent="center"
//           textAlign="center"
//           animation={`${fadeIn} 1s ease-out`}
//         >
//           <Heading
//             as="h1"
//             fontSize={{ base: "3xl", md: "5xl" }}
//             fontWeight="bold"
//             color="white"
//             letterSpacing="wide"
//             textShadow="2px 2px 4px rgba(0,0,0,0.3)"
//             mb={4}
//             fontFamily="Montserat"
//           >
//             BOOKING TIME
//           </Heading>

//           <Text
//             fontSize={{ base: "lg", md: "xl" }}
//             color="gray.100"
//             maxW="2xl"
//             mb={6}
//             fontFamily="Montserat"
//           >
//             B2B Platform for efficient management of bookings and travel
//             services
//           </Text>

//           <Stack
//             direction={{ base: "column", sm: "row" }}
//             spacing={4}
//             align="center"
//           >
//             <Button
//               as={Link}
//               to="/contact"
//               size="lg"
//               px={8}
//               color="white"
//               bg="transparent"
//               border="2px solid"
//               borderColor="white"
//               fontFamily="Montserat"
//               _hover={{
//                 bg: buttonHoverBg,
//                 color: buttonHoverColor,
//                 transform: "translateY(-2px)",
//               }}
//               _active={{
//                 transform: "translateY(0)",
//               }}
//               rightIcon={<ArrowRight size={20} />}
//               transition="all 0.2s"
//             >
//               Try Now!
//             </Button>

//             <Button
//               as="a"
//               href="https://tbs.flytime.ro/reseller/auth/"
//               target="_blank"
//               size="lg"
//               px={8}
//               color="white"
//               bg="transparent"
//               border="2px solid"
//               borderColor="white"
//               fontFamily="Montserat"
//               _hover={{
//                 bg: buttonHoverBg,
//                 color: buttonHoverColor,
//                 transform: "translateY(-2px)",
//               }}
//               _active={{
//                 transform: "translateY(0)",
//               }}
//               rightIcon={<LogIn size={20} />}
//               transition="all 0.2s"
//             >
//               Login
//             </Button>
//           </Stack>
//         </Stack>
//       </Container>

//       {/* Optional: Scroll Indicator */}
//       <Box
//         position="absolute"
//         bottom={4}
//         left="50%"
//         transform="translateX(-50%)"
//         zIndex={2}
//       >
//         <Box
//           width="3px"
//           height="50px"
//           bg="white"
//           opacity={0.6}
//           animation="scrollIndicator 2s infinite"
//           sx={{
//             "@keyframes scrollIndicator": {
//               "0%": { transform: "scaleY(0)", opacity: 0.6 },
//               "50%": { transform: "scaleY(1)", opacity: 0.2 },
//               "100%": { transform: "scaleY(0)", opacity: 0.6 },
//             },
//           }}
//         />
//       </Box>
//     </Box>
//   );
// };

// export default B2bHeader;

import React from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Heading,
  Text,
  VStack,
  HStack,
  useBreakpointValue,
  useColorModeValue,
  Flex,
  IconButton,
  Slide,
  ScaleFade,
} from "@chakra-ui/react";
import { ArrowRight, LogIn, ChevronDown } from "lucide-react";

const B2bHeader = () => {
  const headingSize = useBreakpointValue({ base: "2xl", md: "3xl", lg: "4xl" });
  const buttonSize = useBreakpointValue({ base: "lg", md: "lg" });

  const bgOverlay = useColorModeValue(
    "linear-gradient(130deg, rgba(45, 55, 72, 0.95), rgba(74, 85, 104, 0.9))",
    "linear-gradient(130deg, rgba(26, 32, 44, 0.95), rgba(45, 55, 72, 0.9))"
  );

  const buttonScheme = useColorModeValue({
    light: {
      bg: "whiteAlpha.200",
      hoverBg: "whiteAlpha.300",
      color: "white",
      borderColor: "whiteAlpha.400",
    },
    dark: {
      bg: "blackAlpha.200",
      hoverBg: "blackAlpha.300",
      color: "white",
      borderColor: "whiteAlpha.400",
    },
  });

  return (
    <Box
      as="header"
      position="relative"
      h={{ base: "100vh", md: "85vh" }}
      w="100%"
    >
      {/* Animated Background */}
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        backgroundImage="url('https://www.dcsplus.net/hubfs/banner_IRIX_1903x524_v2c.jpg')"
        backgroundSize="cover"
        backgroundPosition="center"
        backgroundAttachment={{ base: "scroll", md: "fixed" }}
        _before={{
          content: '""',
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          bg: bgOverlay,
          zIndex: 1,
        }}
      />

      {/* Main Content */}
      <Container
        maxW="8xl"
        h="100%"
        position="relative"
        zIndex={2}
        px={{ base: 4, md: 8 }}
      >
        <Flex
          h="100%"
          direction="column"
          align="center"
          justify="center"
          textAlign="center"
          spacing={8}
        >
          <ScaleFade in={true} initialScale={0.9}>
            <VStack spacing={{ base: 8, md: 6 }}>
              <Heading
                as="h1"
                fontSize={headingSize}
                fontWeight="black"
                color="white"
                letterSpacing="wide"
                lineHeight="shorter"
                textShadow="0 2px 10px rgba(0,0,0,0.3)"
                textTransform="uppercase"
                mb={{ base: 4, md: 0 }}
              >
                Booking Time
              </Heading>

              <Text
                fontSize={{ base: "lg", md: "xl", lg: "2xl" }}
                color="whiteAlpha.900"
                maxW="3xl"
                fontWeight="medium"
                px={4}
                mb={{ base: 6, md: 0 }}
              >
                Experience the next generation of B2B travel management platform
              </Text>

              {/* Mobile-optimized button stack */}
              <VStack
                spacing={4}
                w="100%"
                px={4}
                display={{ base: "flex", md: "none" }}
              >
                <Button
                  as={Link}
                  to="/contact"
                  size={buttonSize}
                  width="100%"
                  py={7}
                  color={buttonScheme.color}
                  bg={buttonScheme.bg}
                  borderWidth="1px"
                  borderColor={buttonScheme.borderColor}
                  _hover={{
                    bg: buttonScheme.hoverBg,
                    transform: "translateY(-2px)",
                    boxShadow: "xl",
                  }}
                  _active={{ transform: "translateY(0)" }}
                  rightIcon={<ArrowRight />}
                  transition="all 0.3s"
                  fontSize="lg"
                >
                  Try Now
                </Button>

                <Button
                  as="a"
                  href="https://tbs.flytime.ro/reseller/auth/"
                  target="_blank"
                  size={buttonSize}
                  width="100%"
                  py={7}
                  color={buttonScheme.color}
                  bg={buttonScheme.bg}
                  borderWidth="1px"
                  borderColor={buttonScheme.borderColor}
                  _hover={{
                    bg: buttonScheme.hoverBg,
                    transform: "translateY(-2px)",
                    boxShadow: "xl",
                  }}
                  _active={{ transform: "translateY(0)" }}
                  rightIcon={<LogIn />}
                  transition="all 0.3s"
                  fontSize="lg"
                >
                  Login
                </Button>
              </VStack>

              {/* Desktop button layout */}
              <HStack spacing={6} mt={8} display={{ base: "none", md: "flex" }}>
                <Button
                  as={Link}
                  to="/contact"
                  size={buttonSize}
                  px={8}
                  py={7}
                  color={buttonScheme.color}
                  bg={buttonScheme.bg}
                  borderWidth="1px"
                  borderColor={buttonScheme.borderColor}
                  _hover={{
                    bg: buttonScheme.hoverBg,
                    transform: "translateY(-2px)",
                    boxShadow: "xl",
                  }}
                  _active={{ transform: "translateY(0)" }}
                  rightIcon={<ArrowRight />}
                  transition="all 0.3s"
                >
                  Try Now
                </Button>

                <Button
                  as="a"
                  href="https://tbs.flytime.ro/reseller/auth/"
                  target="_blank"
                  size={buttonSize}
                  px={8}
                  py={7}
                  color={buttonScheme.color}
                  bg={buttonScheme.bg}
                  borderWidth="1px"
                  borderColor={buttonScheme.borderColor}
                  _hover={{
                    bg: buttonScheme.hoverBg,
                    transform: "translateY(-2px)",
                    boxShadow: "xl",
                  }}
                  _active={{ transform: "translateY(0)" }}
                  rightIcon={<LogIn />}
                  transition="all 0.3s"
                >
                  Login
                </Button>
              </HStack>
            </VStack>
          </ScaleFade>
        </Flex>

        {/* Scroll Indicator */}
        <Box
          position="absolute"
          bottom={10}
          left="50%"
          transform="translateX(-50%)"
          opacity={0.7}
          _hover={{ opacity: 1 }}
          transition="opacity 0.3s"
          display={{ base: "none", md: "block" }}
        >
          <IconButton
            aria-label="Scroll down"
            icon={<ChevronDown />}
            variant="ghost"
            color="white"
            size="lg"
            _hover={{ bg: "whiteAlpha.200" }}
            sx={{
              animation: "bounce 2s infinite",
              "@keyframes bounce": {
                "0%, 100%": { transform: "translateY(0)" },
                "50%": { transform: "translateY(10px)" },
              },
            }}
          />
        </Box>
      </Container>
    </Box>
  );
};

export default B2bHeader;
