// import { useNavigate } from "react-router-dom";
// import {
//   Box,
//   Container,
//   Heading,
//   Center,
//   Text,
//   Stack,
//   Flex,
//   Icon,
//   Image,
//   Button,
//   useBreakpointValue,
// } from "@chakra-ui/react";
// import { GiAirplaneDeparture } from "react-icons/gi";
// import { Link } from "react-router-dom";

// const categories = [
//   {
//     icon: GiAirplaneDeparture, // Replace with actual icon
//     title: "Amadeus GDS",
//     text: "is a global leader in providing technology solutions for the airline ticketing and travel industry. It offers fast and easy access to a vast inventory of airline tickets, with over 400 full-service, low-cost, regional, and hybrid airlines. Travel agents are connected to airline availability through a comprehensive, efficient, and high-performance tool that is seamlessly integrated into our platform, enabling quick management and issuance of airline tickets.",
//     logo: "/images/furnizori/Amadeus-Logo.jpg",
//   },
//   {
//     icon: GiAirplaneDeparture,
//     title: "NDC-X Amadeus  ",
//     text: "is a top-tier tool that integrates the new NDC (New Distribution Capability) standard, modernizing the way airline tickets are sold and managed. This allows direct connections with airlines to access personalized, negotiated, and flexible fares, as well as various additional services.",
//     logo: "/images/furnizori/Amadeus-Logo.jpg",
//   },
//   {
//     icon: GiAirplaneDeparture,
//     title: "Farelogix NDC Lufthansa Group",
//     text: "is a leading airline ticket distribution provider within the Lufthansa Group, utilizing the NDC (New Distribution Capability) standard to enable direct access to the inventory of Lufthansa, Austrian Airlines, Swiss Air, SN Brussels, Discover Airlines, and Eurowings. This modern standard transforms the way airline tickets are sold and managed, giving reselling agents a real competitive advantage by allowing direct connectivity with airline inventory, offering personalized, negotiated fares, flexible options, and a wide range of ancillary services. Farelogix provides reselling subagents with the necessary tools to offer customers the best travel options while improving efficiency and profitability. ",
//     logo: "/images/furnizori/Lufthansa-logo.jpg",
//   },
//   {
//     icon: GiAirplaneDeparture,
//     title: "Farelogix NDC Aegeean Airlines ",
//     text: "is the solution provided by the Greek airline company for ticketing agents, designed to integrate the new NDC (New Distribution Capability) standard, covering all Aegean Airlines routes and offering the most competitive fares updated in real time, along with a variety of ancillary services.",
//     logo: "/images/furnizori/Aegean-Airlines-Logo.jpg",
//   },
//   {
//     icon: GiAirplaneDeparture,
//     title: "Travelfusion",
//     text: "is a global leader in travel distribution technology, offering advanced solutions through LLC (Low-Cost Carrier) content as well as the new NDC (New Distribution Capability) standard. By integrating Travelfusion, we have provided reselling sub-agents with the necessary tools to access over 300 low-cost airlines worldwide and more than 25 full-service airlines that have adopted NDC.",
//     logo: "/images/furnizori/Travelfusion-Logo-colour-high-res-01.jpg",
//   },
// ];

// const CategoryBox = ({ icon, text, title, logo }) => {
//   // const displayBox = useBreakpointValue({ base: "block", md: "flex" });
//   const flexDirection = useBreakpointValue({ base: "column", md: "row" });
//   const textAlign = useBreakpointValue({ base: "center" });
//   return (
//     <Box
//       p={4}
//       alignItems="center"
//       borderWidth="1px"
//       borderRadius="lg"
//       width="100%"
//       mb={4}
//       _hover={{ shadow: "md" }}
//     >
//       <Flex direction={flexDirection} alignItems="center">
//         <Center>
//           <Icon as={icon} boxSize={6} mr={4} aria-label={title} />
//         </Center>
//         <Flex
//           direction="column"
//           align={textAlign === "center" ? "center" : "flex-start"}
//           flex="1"
//         >
//           <Center fontSize={25} fontWeight={600} mb={2} fontFamily="Montserat">
//             {title}
//           </Center>
//           <Center mb={2}>
//             <Image src={logo} alt={`${title} logo`} maxW="150px" />
//           </Center>
//           <Text textAlign={textAlign} fontSize={17} fontFamily="Montserat">
//             {text}
//           </Text>
//         </Flex>
//       </Flex>
//     </Box>
//   );
// };

// const Ticketing = () => {
//   const history = useNavigate();

//   const handleButtonClick = () => {
//     // Scroll to the top of the page
//     window.scrollTo(0, 0);

//     // Navigate to the '/vacante' route
//     history.push("/contact");
//   };

//   return (
//     <Box
//       // backgroundImage="url('/background.svg')"
//       backgroundSize="cover"
//       backgroundPosition="center"
//       py={5}
//       id="ticketing"
//     >
//       <Container maxW="container.md">
//         <Heading
//           as="h2"
//           size="xl"
//           mb={6}
//           ml={5}
//           textAlign="center"
//           color="black"
//         >
//           Air Services
//         </Heading>
//         <Stack spacing={4}>
//           {categories.map((category, index) => (
//             <CategoryBox
//               key={index}
//               icon={category.icon}
//               title={category.title}
//               text={category.text}
//               logo={category.logo}
//             />
//           ))}
//         </Stack>
//         <Center
//           justify="center"
//           align="center"
//           as="h1"
//           fontSize={{ base: "2xl", md: "3xl" }}
//           mt="15px"
//         >
//           <Link to="/contact">
//             <Button
//               fontSize="20px"
//               target="_blank"
//               colorScheme="red"
//               variant="outline"
//               mr={4}
//               color="red"
//               bg="white.400"
//               borderRadius="25px"
//               _hover={{
//                 boxShadow: "4px 4px rgba(255, 99, 71,0.9)",
//               }}
//               onClick={handleButtonClick}
//             >
//               Request a demo account!
//             </Button>
//           </Link>
//         </Center>
//       </Container>
//     </Box>
//   );
// };

// export default Ticketing;

import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Heading,
  Text,
  Flex,
  Image,
  Button,
  VStack,
  HStack,
  Circle,
  Badge,
  useColorModeValue,
  Icon,
  SimpleGrid,
  useBreakpointValue,
} from "@chakra-ui/react";
import { GiAirplaneDeparture } from "react-icons/gi";
import { MdArrowForward } from "react-icons/md";
import { Link } from "react-router-dom";

const categories = [
  {
    icon: GiAirplaneDeparture,
    title: "Amadeus GDS",
    text: "is a global leader in providing technology solutions for the airline ticketing and travel industry. It offers fast and easy access to a vast inventory of airline tickets, with over 400 full-service, low-cost, regional, and hybrid airlines.",
    logo: "/images/furnizori/Amadeus-Logo.jpg",
    stats: ["100+ Airlines", "Global Coverage", "Real-time Access"],
  },
  {
    icon: GiAirplaneDeparture,
    title: "NDC-X Amadeus",
    text: "is a top-tier tool that integrates the new NDC (New Distribution Capability) standard, modernizing the way airline tickets are sold and managed. This allows direct connections with airlines to access personalized, negotiated, and flexible fares.",
    logo: "/images/furnizori/Amadeus-Logo.jpg",
    stats: ["NDC Standard", "Flexible Fares", "Direct Connect"],
  },
  {
    icon: GiAirplaneDeparture,
    title: "Farelogix NDC Lufthansa Group",
    text: "is a leading airline ticket distribution provider within the Lufthansa Group, utilizing the NDC standard to enable direct access to the inventory of Lufthansa, Austrian Airlines, Swiss Air, SN Brussels, Discover Airlines, and Eurowings.",
    logo: "/images/furnizori/Lufthansa-logo.jpg",
    stats: ["6+ Airlines", "NDC Provider", "Premium Service"],
  },
  {
    icon: GiAirplaneDeparture,
    title: "Farelogix NDC Aegean Airlines",
    text: "is the solution provided by the Greek airline company for ticketing agents, designed to integrate the new NDC standard, covering all Aegean Airlines routes and offering the most competitive fares updated in real time.",
    logo: "/images/furnizori/Aegean-Airlines-Logo.jpg",
    stats: ["Real-time Fares", "Full Coverage", "NDC Provider"],
  },
  {
    icon: GiAirplaneDeparture,
    title: "Travelfusion",
    text: "is a global leader in travel distribution technology, offering advanced solutions through LLC content as well as the new NDC standard. Access over 300 low-cost airlines worldwide and more than 25 full-service airlines.",
    logo: "/images/furnizori/Travelfusion-Logo-colour-high-res-01.jpg",
    stats: ["100+ Airlines", "100+ Partners", "Global Network"],
  },
];

const ServiceCard = ({ icon, title, text, logo, stats }) => {
  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.700");

  return (
    <Box
      position="relative"
      bg={bgColor}
      p={8}
      borderRadius="2xl"
      border="1px"
      borderColor={borderColor}
      _hover={{
        transform: "translateY(-4px)",
        shadow: "xl",
        transition: "all 0.3s ease",
      }}
      overflow="hidden"
    >
      {/* Decorative Elements */}
      <Circle
        size="200px"
        position="absolute"
        top="-100px"
        right="-100px"
        bg="blue.50"
        opacity={0.3}
      />

      <VStack align="stretch" spacing={6}>
        <HStack spacing={4}>
          <Circle size="40px" bg="blue.50">
            <Icon as={icon} w={5} h={5} color="blue.500" />
          </Circle>
          <Heading size="md" fontWeight="700">
            {title}
          </Heading>
        </HStack>

        <Box position="relative">
          <Image
            src={logo}
            alt={`${title} logo`}
            maxW="150px"
            maxH="90px"
            objectFit="contain"
            mx="auto"
            filter="grayscale(0.3)"
            _hover={{ filter: "grayscale(0)", transform: "scale(1.05)" }}
            transition="all 0.3s ease"
          />
        </Box>

        <Text color="gray.600" fontSize="md">
          {text}
        </Text>

        <HStack spacing={4} justify="center" flexWrap="wrap">
          {stats.map((stat, index) => (
            <Badge
              key={index}
              px={3}
              py={1}
              borderRadius="full"
              colorScheme="blue"
              variant="subtle"
              fontSize="sm"
            >
              {stat}
            </Badge>
          ))}
        </HStack>
      </VStack>
    </Box>
  );
};

const Ticketing = () => {
  const navigate = useNavigate();
  const columns = useBreakpointValue({ base: 1, lg: 2 });

  const handleDemoRequest = () => {
    window.scrollTo(0, 0);
    navigate("/contact");
  };

  return (
    <Box py={20} bg="gray.50" id="ticketing">
      <Container maxW="container.xl">
        <VStack spacing={16}>
          <VStack spacing={4} textAlign="center">
            <Heading
              size="2xl"
              bgGradient="linear(to-r, blue.400, blue.600)"
              bgClip="text"
              letterSpacing="tight"
            >
              Air Services
            </Heading>
            <Text fontSize="xl" color="gray.600" maxW="container.md">
              Connect with leading airline providers and access comprehensive
              flight inventory worldwide
            </Text>
          </VStack>

          <SimpleGrid columns={columns} spacing={8} w="full">
            {categories.map((category, index) => (
              <ServiceCard key={index} {...category} />
            ))}
          </SimpleGrid>

          <Button
            size="lg"
            colorScheme="blue"
            rightIcon={<MdArrowForward />}
            onClick={handleDemoRequest}
            borderRadius="full"
            px={8}
            _hover={{
              transform: "translateY(-2px)",
              shadow: "lg",
            }}
          >
            Request Demo Account
          </Button>
        </VStack>
      </Container>
    </Box>
  );
};

export default Ticketing;
