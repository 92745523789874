import React from "react";
import {
  Box,
  Container,
  Heading,
  Text,
  Grid,
  GridItem,
  VStack,
  HStack,
  Circle,
  Divider,
  useColorModeValue,
  keyframes,
} from "@chakra-ui/react";
import {
  LineChart,
  Target,
  TrendingUp,
  Users,
  Lightbulb,
  ArrowUpRight,
} from "lucide-react";

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const ConsultingSection = () => {
  const bgColor = useColorModeValue("white", "gray.900");
  const accentColor = useColorModeValue("blue.500", "blue.300");
  const textColor = useColorModeValue("gray.700", "gray.200");
  const borderColor = useColorModeValue("gray.200", "gray.700");

  const processSteps = [
    {
      icon: Target,
      title: "In-Depth Analysis",
      text: "We assess existing technologies, processes, and behaviors",
    },
    {
      icon: LineChart,
      title: "Optimization",
      text: "We identify opportunities for savings and efficiency",
    },
    {
      icon: Users,
      title: "Collaboration",
      text: "We work together to implement solutions",
    },
    {
      icon: TrendingUp,
      title: "Results",
      text: "We monitor and measure the impact of changes",
    },
  ];

  return (
    <Box
      py={{ base: 12, md: 20 }}
      bg={bgColor}
      animation={`${fadeIn} 2s ease-out`}
    >
      <Container maxW="container.xl">
        <Grid
          templateColumns={{ base: "1fr", lg: "1fr 1px 1fr" }}
          gap={{ base: 10, lg: 0 }}
        >
          {/* Left Section - Main Content */}
          <GridItem>
            <VStack align="start" spacing={6} pr={{ base: 0, lg: 12 }}>
              <HStack spacing={3}>
                <Lightbulb size={32} color="#3182CE" />
                <Heading
                  as="h2"
                  size="xl"
                  color="blue.600"
                  fontFamily="Montserrat"
                >
                  Strategic advisory
                </Heading>
              </HStack>

              <Text
                fontSize="lg"
                color={textColor}
                lineHeight="tall"
                fontFamily="Montserrat"
              >
                At Travel Time, we go beyond the surface to examine every aspect
                of your travel program. From evaluating present technology,
                processes, supplier relationships and booking behaviors to
                identifying inefficiencies and areas for improvement, our
                strategic advisory services uncover hidden savings and deliver
                actionable insights.
              </Text>

              <Text
                fontSize="lg"
                color={textColor}
                lineHeight="tall"
                fontFamily="Montserrat"
              >
                Our expert team works closely with your organization to redefine
                travel programs, aligning them with your corporate goals while
                enhancing traveler compliance and satisfaction. By leveraging
                industry-leading technology and forecasting tools, we implement
                tailored solutions designed to optimize your travel program and
                reduce costs.
              </Text>

              <Text
                fontSize="lg"
                color={textColor}
                lineHeight="tall"
                fontFamily="Montserrat"
              >
                Furthermore, we stay ahead of industry trends to help you
                navigate upcoming challenges and seize emerging opportunities.
                Whether it’s negotiating better supplier agreements, improving
                traveler experience, or preparing for future developments in
                corporate travel, our strategic approach ensures measurable
                results and lasting impact.
              </Text>
            </VStack>
          </GridItem>

          {/* Center Divider */}
          <GridItem display={{ base: "none", lg: "block" }}>
            <Box w="1px" h="full" bg={borderColor} />
          </GridItem>

          {/* Right Section - Process Steps */}
          <GridItem>
            <VStack align="stretch" spacing={8} pl={{ base: 0, lg: 12 }}>
              <Box
                p={6}
                bg="blue.500"
                color="white"
                borderRadius="xl"
                position="relative"
                overflow="hidden"
              >
                <Box
                  position="absolute"
                  right="-20px"
                  top="-20px"
                  opacity={0.1}
                >
                  <ArrowUpRight size={120} />
                </Box>
                <Heading size="md" mb={3} fontFamily="Montserrat">
                  Consulting Process
                </Heading>
                <Text fontSize="md" fontFamily="Montserrat">
                  Our systematic approach ensures optimal results for your
                  travel program.
                </Text>
              </Box>

              <VStack spacing={6} align="stretch">
                {processSteps.map((step, idx) => (
                  <HStack
                    key={idx}
                    spacing={4}
                    p={6}
                    borderWidth="1px"
                    borderColor={borderColor}
                    borderRadius="lg"
                    transition="all 0.3s"
                    _hover={{
                      transform: "translateX(8px)",
                      borderColor: accentColor,
                      boxShadow: "md",
                    }}
                  >
                    <Circle size="40px" bg="blue.50">
                      <step.icon size={20} color="#3182CE" />
                    </Circle>
                    <VStack align="start" spacing={1}>
                      <Text
                        fontWeight="bold"
                        color="blue.600"
                        fontFamily="Montserrat"
                      >
                        {step.title}
                      </Text>
                      <Text
                        color={textColor}
                        fontSize="sm"
                        fontFamily="Montserrat"
                      >
                        {step.text}
                      </Text>
                    </VStack>
                  </HStack>
                ))}
              </VStack>
            </VStack>
          </GridItem>
        </Grid>
      </Container>
    </Box>
  );
};

export default ConsultingSection;
