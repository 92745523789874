// import React, { useMemo } from "react";
// import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
// import "./google-maps.css";

// const GoogleMaps = () => {
//   const { isLoaded } = useLoadScript({
//     googleMapsApiKey: process.env.React_App_API_GOOGLE_MAPS_NEW,
//   });

//   if (!isLoaded) return <div>Loading...</div>;
//   return <Map />;

//   function Map() {
//     const center = useMemo(
//       () => ({ lat: 44.44349201566385, lng: 26.085209350277168 }),
//       []
//     );

//     return (
//       <GoogleMap
//         zoom={15}
//         center={center}
//         mapContainerClassName="map-container"
//       >
//         <MarkerF position={center} />
//       </GoogleMap>
//     );
//   }
// };

// export default GoogleMaps;

import React, { useMemo } from "react";
import { GoogleMap, useLoadScript, MarkerF } from "@react-google-maps/api";
import {
  Box,
  Container,
  Skeleton,
  Text,
  VStack,
  useColorModeValue,
  Icon,
  Heading,
} from "@chakra-ui/react";
import { FaMapMarkerAlt } from "react-icons/fa";

const MapComponent = () => {
  const center = useMemo(
    () => ({ lat: 44.44349201566385, lng: 26.085209350277168 }),
    []
  );

  const mapOptions = useMemo(
    () => ({
      disableDefaultUI: false,
      clickableIcons: true,
      scrollwheel: true,
      styles: [
        {
          featureType: "all",
          elementType: "labels.text.fill",
          stylers: [{ color: "#7c93a3" }],
        },
        {
          featureType: "administrative",
          elementType: "geometry.fill",
          stylers: [{ visibility: "on" }],
        },
        {
          featureType: "water",
          elementType: "geometry.fill",
          stylers: [{ color: "#d3eaf8" }],
        },
      ],
    }),
    []
  );

  return (
    <GoogleMap
      zoom={15}
      center={center}
      mapContainerClassName="map-container"
      options={mapOptions}
    >
      <MarkerF
        position={center}
        icon={{
          path: "M12 0C7.58 0 4 3.58 4 8c0 5.25 8 13 8 13s8-7.75 8-13c0-4.42-3.58-8-8-8zm0 11c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3z",
          fillColor: "#4299E1",
          fillOpacity: 1,
          strokeWeight: 1,
          strokeColor: "#FFFFFF",
          scale: 2,
        }}
      />
    </GoogleMap>
  );
};

const GoogleMaps = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.React_App_API_GOOGLE_MAPS_NEW,
  });

  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.600");

  return (
    <Container maxW="7xl" py={8}>
      <VStack spacing={6} align="stretch">
        <Box textAlign="center">
          <Heading
            size="lg"
            mb={2}
            bgGradient="linear(to-r, blue.400, teal.400)"
            bgClip="text"
          >
            Our Location
          </Heading>
          <Text color="gray.600" fontSize="md" fontFamily="Montserat">
            Mircea Vulcanescu Street, No. 45, Sector 1, Bucharest
          </Text>
        </Box>

        <Box
          borderWidth="1px"
          borderRadius="xl"
          overflow="hidden"
          borderColor={borderColor}
          bg={bgColor}
          boxShadow="xl"
        >
          {!isLoaded ? (
            <VStack spacing={4} p={8}>
              <Skeleton height="400px" width="100%" borderRadius="xl" />
              <Text color="gray.500" fontSize="md" fontFamily="Montserat">
                Loading map...
              </Text>
            </VStack>
          ) : (
            <Box height="500px" width="100%" position="relative">
              <MapComponent />
            </Box>
          )}
        </Box>

        <Box
          p={4}
          borderRadius="lg"
          bg={useColorModeValue("gray.50", "gray.700")}
          textAlign="center"
        >
          <VStack spacing={2}>
            <Icon as={FaMapMarkerAlt} w={6} h={6} color="blue.400" />
            <Text fontWeight="medium" fontFamily="Montserat">
              Business Hours:
            </Text>
            <Text color="gray.600" fontFamily="Montserat">
              Monday - Friday: 09:00 - 18:00
            </Text>
          </VStack>
        </Box>
      </VStack>

      <style jsx global>{`
        .map-container {
          width: 100%;
          height: 100%;
          border-radius: 0.75rem;
        }
      `}</style>
    </Container>
  );
};

export default GoogleMaps;
