import React from "react";
import {
  Box,
  Container,
  SimpleGrid,
  VStack,
  Image,
  Text,
  Link,
  Heading,
  useColorModeValue,
  Flex,
  Center,
  keyframes,
} from "@chakra-ui/react";
import { EmailIcon } from "@chakra-ui/icons";

// Animation keyframes
const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const TeamHeader = () => {
  return (
    <Box position="relative" py={16} overflow="hidden">
      {/* Background geometric shapes */}
      <Box position="absolute" inset={0} opacity={0.1}>
        <Box
          position="absolute"
          top={0}
          left="25%"
          w="128px"
          h="128px"
          bgGradient="linear(to-r, blue.600, purple.200)"
          borderRadius="full"
          filter="blur(40px)"
        />
        <Box
          position="absolute"
          top="25%"
          right="25%"
          w="128px"
          h="128px"
          bgGradient="linear(to-r, purple.200, blue.600)"
          borderRadius="full"
          filter="blur(40px)"
        />
      </Box>

      {/* Main content */}
      <VStack position="relative" zIndex={1} spacing={6}>
        <Heading
          as="h1"
          fontSize={{ base: "4xl", md: "6xl" }}
          fontWeight="bold"
          bgGradient="linear(to-r, blue.600, purple.600)"
          bgClip="text"
          textAlign="center"
        >
          {/* Certifications & Awards */}
          Management team
        </Heading>

        {/* Animated underline */}
        <Box
          w="24"
          h="1"
          bgGradient="linear(to-r, blue.500, purple.500)"
          position="relative"
        >
          <Box
            position="absolute"
            top={0}
            left={0}
            w="full"
            h="full"
            bgGradient="linear(to-r, purple.500, blue.500)"
            animation={`${gradientAnimation} 3s ease infinite`}
          />
        </Box>
      </VStack>

      {/* Decorative elements */}
      <Flex
        position="absolute"
        bottom={0}
        left={0}
        w="full"
        justify="space-between"
        opacity={0.2}
      >
        <Box
          w="16"
          h="16"
          borderLeft="2px"
          borderTop="2px"
          borderColor="blue.500"
        />
        <Box
          w="16"
          h="16"
          borderRight="2px"
          borderTop="2px"
          borderColor="purple.500"
        />
      </Flex>
    </Box>
  );
};

const TeamMember = ({ name, email, placeholder, jobName }) => {
  const bgColor = useColorModeValue("white", "gray.700");
  const textColor = useColorModeValue("gray.800", "white");
  const emailColor = useColorModeValue("blue.500", "blue.300");

  return (
    <Box
      bg={bgColor}
      rounded="lg"
      shadow="base"
      p={6}
      _hover={{ shadow: "lg" }}
      transition="all 0.3s"
      maxW="300px" // Added fixed maximum width
      w="full"
    >
      <VStack spacing={4}>
        {/* Profile Image */}
        <Center>
          {" "}
          {/* Centered container for image */}
          <Box
            rounded="full"
            overflow="hidden"
            w="32"
            h="32"
            position="relative"
          >
            <Image
              src={placeholder}
              alt={`${name}'s profile`}
              w="full"
              h="full"
              objectFit="cover"
            />
          </Box>
        </Center>

        {/* Name */}
        <Text
          fontSize="xl"
          fontWeight="semibold"
          color={textColor}
          textAlign="center" // Centered text
          sx={{ fontFamily: "'Montserrat', sans-serif" }}
        >
          {name}
        </Text>

        {/* jobName */}
        <Text
          fontSize="sm"
          fontWeight="italic"
          color={textColor}
          textAlign="center" // Centered text
          sx={{ fontFamily: "'Montserrat', sans-serif" }}
        >
          {jobName}
        </Text>

        {/* Email */}
        <Flex
          align="center"
          justify="center"
          sx={{ fontFamily: "'Montserrat', sans-serif" }}
        >
          {" "}
          {/* Centered flex container */}
          <EmailIcon mr={2} color="gray.500" />
          <Link
            href={`mailto:${email}`}
            color={emailColor}
            _hover={{ textDecoration: "none", color: "blue.600" }}
          >
            {email}
          </Link>
        </Flex>
      </VStack>
    </Box>
  );
};

const TeamGrid = () => {
  const teamMembers = [
    {
      name: "Andrei Ștefan",
      jobName: "General Manager",
      email: "andrei.stefan@travel-time.ro",
      placeholder: "https://travel-time.ro/images/team/AndreiS.webp",
    },
    {
      name: "Gabriel Mandache",
      jobName: "Managing Partner",
      email: "gabi.mandache@travel-time.ro",
      placeholder: "https://travel-time.ro/images/team/Gabi.webp",
    },
    {
      name: "Corina Miulescu",
      jobName: "Agency Manager",
      email: "corina.miulescu@travel-time.ro",
      placeholder: "https://travel-time.ro/images/team/Corina.webp",
    },
  ];

  return (
    <Container maxW="container.xl" py={8}>
      <TeamHeader />
      <Center>
        {" "}
        {/* Center the entire grid */}
        <SimpleGrid
          columns={{ base: 1, sm: 2, lg: 3 }}
          spacing={20}
          justifyItems="center" // Center grid items
          maxW="1000px" // Maximum width for the grid
        >
          {teamMembers.map((member, index) => (
            <TeamMember
              key={index}
              name={member.name}
              jobName={member.jobName}
              email={member.email}
              placeholder={member.placeholder}
            />
          ))}
        </SimpleGrid>
      </Center>
    </Container>
  );
};

export default TeamGrid;
