import {
  Box,
  Container,
  Heading,
  Text,
  Grid,
  GridItem,
  VStack,
  HStack,
  Flex,
  Center,
  useBreakpointValue,
  Circle,
  Stat,
  StatNumber,
  StatLabel,
  useColorModeValue,
} from "@chakra-ui/react";
import {
  Users,
  Shield,
  Globe2,
  Clock,
  Award,
  BookOpen,
  HeartHandshake,
  UserCheck,
  AlarmCheck,
  Binary,
  Phone,
  BadgeCheck,
} from "lucide-react";

export const ContentCapabilities = () => {
  const subHeadingSize = useBreakpointValue({
    base: "xl",
    md: "2xl",
    lg: "3xl",
  });

  const bgColor = useColorModeValue("gray.50", "gray.900");
  const cardBg = useColorModeValue("white", "gray.800");
  const statBg = useColorModeValue("blue.50", "blue.900");
  const borderColor = useColorModeValue("gray.200", "gray.700");

  const stats = [
    { number: "30+", label: "Professionals", icon: Users },
    { number: "15+", label: "Years of Experience", icon: Clock },
    { number: "24/7", label: "Support", icon: Phone },
    { number: "100%", label: "Dedication", icon: HeartHandshake },
  ];

  const sections = [
    {
      title: "Dedicated team",
      icon: UserCheck,
      content: [
        "Our consultants bring extensive experience in all areas of corporate travel management, ensuring every client receives comprehensive and personalized services while maintaining the highest standards of customer care.",
        "Based on your organization’s travel volume, Travel Time assigns a dedicated team of 2 to 9 professional consultants who are fully committed to optimizing your travel program.",
        "Beyond their technical skills, our team members are driven by a results-oriented approach and a deep dedication to helping clients succeed. Years of collaboration have forged a cohesive and agile team, capable of making swift and well-informed decisions even in challenging situations.",
      ],
    },
    {
      title: "Unrivaled expertise",
      icon: Award,
      content: [
        "Travel Time’s team consists of over 30 highly skilled, experienced professionals fluent in English and proficient in a couple additional languages, ensuring seamless coordination across multiple projects and regions.",
        "Our travel consultants have an average of 15 years of industry expertise, many with an extensive ticketing background, equipping them with in-depth knowledge to navigate complex travel scenarios. They continuously enhance their skills through specialized training programs held both locally and internationally.",
        "In an industry defined by constant change, our knowledgeable and adaptable staff ensures your travel needs are met with precision and foresight. Travel Time’s commitment to regular training investments keeps our team ahead of the curve, ready to deliver outstanding results.",
      ],
    },
    {
      title: "Duty of Care: ensuring safety",
      icon: Shield,
      content: [
        "At Travel Time, duty of care is a fundamental priority in our travel management approach. ",
        "With advanced tools, we can pinpoint traveler locations in real time, enabling us to proactively address disruptions and ensure safety.",
        "Our 24/7 assistance protocol empowers travel managers to communicate seamlessly during emergencies, offering immediate solutions and support. Whether it’s an unforeseen delay or a critical incident, we provide a reliable safety net for every traveler, every time.",
      ],
    },
  ];

  const features = [
    { icon: Globe2, text: "Global Coverage" },
    { icon: BookOpen, text: "Continuous Training" },
    { icon: Binary, text: "Advanced Systems" },
    { icon: AlarmCheck, text: "Quick Response" },
    { icon: BadgeCheck, text: "Certifications" },
    { icon: Shield, text: "Guaranteed Safety" },
  ];
  return (
    <>
      <Box
        position="relative"
        w="full"
        py={{ base: 8, md: 12 }}
        display="flex"
        justifyContent="center"
      >
        <Box
          position="relative"
          w={{ base: "100%", md: "75%" }}
          h={{ base: "250px", md: "500px" }}
          borderRadius={{ base: "none", md: "xl" }}
          overflow="hidden"
          boxShadow={{ base: "none", md: "xl" }}
        >
          {/* Image Container */}
          <Box
            w="full"
            h="full"
            bgColor="gray.200"
            backgroundImage="url('https://travel-time.ro/images/newImages/shutterstock_2177147503.jpg')"
            backgroundPosition="center"
            backgroundRepeat="no-repeat"
            backgroundSize="cover"
          />

          {/* Gradient Overlay for text */}
          <Box
            position="absolute"
            bottom="0"
            left="0"
            right="0"
            h="50%"
            bgGradient="linear(to-t, blackAlpha.800, blackAlpha.400, transparent)"
          />

          {/* Title Container */}
          <Center
            position="absolute"
            bottom="0"
            left="0"
            right="0"
            p={{ base: 6, md: 8 }}
            align="flex-start"
            justify="flex-start"
          >
            <Heading
              as="h2"
              size={subHeadingSize}
              color="white"
              fontFamily="Montserrat"
              textShadow="2px 2px 4px rgba(0,0,0,0.4)"
              maxW="container.lg"
            >
              Capabilities - Blending technology with proffesional expertise
            </Heading>
          </Center>
        </Box>
      </Box>

      <Box py={{ base: 12, md: 20 }} bg={bgColor}>
        <Container maxW="container.xl">
          <VStack spacing={16}>
            {/* Stats Section */}
            <Grid
              templateColumns={{ base: "repeat(2, 1fr)", md: "repeat(4, 1fr)" }}
              gap={6}
              w="full"
            >
              {stats.map((stat, idx) => (
                <GridItem key={idx}>
                  <Box
                    bg={statBg}
                    p={6}
                    borderRadius="xl"
                    textAlign="center"
                    transition="all 0.3s"
                    _hover={{ transform: "translateY(-4px)" }}
                  >
                    <VStack spacing={3}>
                      <Circle size="50px" bg="blue.500" color="white">
                        <stat.icon size={24} />
                      </Circle>
                      <Stat>
                        <StatNumber fontSize="3xl" color="blue.600">
                          {stat.number}
                        </StatNumber>
                        <StatLabel fontSize="sm" color="gray.600">
                          {stat.label}
                        </StatLabel>
                      </Stat>
                    </VStack>
                  </Box>
                </GridItem>
              ))}
            </Grid>

            {/* Main Content Sections */}
            {sections.map((section, idx) => (
              <Box
                key={idx}
                w="full"
                bg={cardBg}
                borderRadius="xl"
                boxShadow="xl"
                overflow="hidden"
              >
                <Grid templateColumns={{ base: "1fr", lg: "1fr 3fr" }} gap={8}>
                  <GridItem
                    bg="blue.500"
                    p={8}
                    color="white"
                    position="relative"
                    overflow="hidden"
                  >
                    <Box
                      position="absolute"
                      right="-20%"
                      bottom="-20%"
                      opacity={0.2}
                    >
                      <section.icon size={200} />
                    </Box>
                    <VStack align="start" spacing={4} position="relative">
                      <section.icon size={40} />
                      <Heading as="h3" size="lg" fontFamily="Montserrat">
                        {section.title}
                      </Heading>
                    </VStack>
                  </GridItem>

                  <GridItem p={8}>
                    <VStack align="start" spacing={6}>
                      {section.content.map((text, textIdx) => (
                        <Text
                          key={textIdx}
                          fontSize="lg"
                          color="gray.700"
                          lineHeight="tall"
                        >
                          {text}
                        </Text>
                      ))}
                    </VStack>
                  </GridItem>
                </Grid>
              </Box>
            ))}

            {/* Features Grid */}
            <Grid
              templateColumns={{
                base: "repeat(2, 1fr)",
                md: "repeat(3, 1fr)",
                lg: "repeat(6, 1fr)",
              }}
              gap={4}
              w="full"
            >
              {features.map((feature, idx) => (
                <GridItem key={idx}>
                  <VStack
                    p={4}
                    bg={cardBg}
                    borderRadius="lg"
                    borderWidth="1px"
                    borderColor={borderColor}
                    spacing={3}
                    transition="all 0.3s"
                    _hover={{
                      transform: "translateY(-2px)",
                      shadow: "md",
                      borderColor: "blue.200",
                    }}
                  >
                    <Circle size="40px" bg="blue.50">
                      <feature.icon size={20} color="#3182CE" />
                    </Circle>
                    <Text
                      fontSize="sm"
                      fontWeight="medium"
                      color="gray.700"
                      textAlign="center"
                    >
                      {feature.text}
                    </Text>
                  </VStack>
                </GridItem>
              ))}
            </Grid>
          </VStack>
        </Container>
      </Box>
    </>
  );
};

export default ContentCapabilities;
