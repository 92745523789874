import React from "react";
import {
  Box,
  Container,
  Grid,
  Heading,
  Text,
  VStack,
  List,
  ListItem,
  ListIcon,
  Image,
  Flex,
} from "@chakra-ui/react";
import { CheckCircle } from "lucide-react";

const Section = ({ title, children }) => (
  <VStack
    align="stretch"
    spacing={4}
    p={6}
    bg="white"
    borderRadius="lg"
    boxShadow="lg"
    _hover={{ boxShadow: "xl" }}
    transition="all 0.3s"
  >
    <Heading
      as="h3"
      fontSize={["xl", "2xl"]}
      color="#000f40"
      borderBottom="2px solid"
      borderColor="blue.200"
      pb={2}
      sx={{ fontFamily: "'Montserrat', sans-serif" }}
    >
      {title}
    </Heading>
    {children}
  </VStack>
);

const MissionVisionValues = () => {
  const values = [
    {
      title: "Partnership",
      description: "The foundation of long-term successful relationships.",
    },
    {
      title: "Service Excellence ",
      description: "Our clients success is also our success.",
    },
    {
      title: "Integrity",
      description:
        "We always keep our promises, acting with transparency and honesty.",
    },
    {
      title: "Team",
      description:
        "The strength and driving force behind Travel Time, essential to every accomplishment.",
    },
  ];

  return (
    <Container maxW="container.xl" py={6}>
      <Grid
        templateColumns={["1fr", "1fr", "3fr 3fr"]}
        gap={8}
        alignItems="stretch"
      >
        <Flex
          display={["block", "none", "flex"]}
          position="relative"
          height="100%"
        >
          <Box
            position="sticky"
            top="2rem"
            width="100%"
            height="calc(100vh - 4rem)"
            maxHeight={["400px", "500px", "710px"]}
            borderRadius="xl"
            overflow="hidden"
          >
            <Image
              src="https://travel-time.ro/images/newImages/shutterstock_1499306735.jpg"
              alt="mission value vision image"
              width="100%"
              height="100%"
              objectFit="cover"
              borderRadius="xl"
              boxShadow="2xl"
              transition="transform 0.3s"
              _hover={{ transform: "scale(1.02)" }}
            />
            <Box
              position="absolute"
              bottom={0}
              left={0}
              right={0}
              height="50%"
              bgGradient="linear(to-t, rgba(0,15,64,0.7), transparent)"
              borderBottomRadius="xl"
              pointerEvents="none"
            />
          </Box>
        </Flex>

        <VStack spacing={8} align="stretch">
          <Section title="Mission">
            <Text
              fontSize="md"
              color="gray.700"
              lineHeight="tall"
              sx={{ fontFamily: "'Montserrat', sans-serif" }}
            >
              To contribute to clients and partners success through bespoke
              travel management solutions. We are committed to adding value with
              relevant content and continuous support, providing a complete
              experience tailored to each client’s needs.
            </Text>
          </Section>

          <Section title="Vision">
            <Text
              fontSize="md"
              color="gray.700"
              lineHeight="tall"
              sx={{ fontFamily: "'Montserrat', sans-serif" }}
            >
              To become the leading consolidator in Romania for airline tickets,
              B2B services, and travel management, delivering excellence in
              services that support the performance and efficiency of our
              clients.
            </Text>
          </Section>

          <Section title="Values">
            <List spacing={6}>
              {values.map((value, index) => (
                <ListItem key={index} display="flex" alignItems="flex-start">
                  <ListIcon as={CheckCircle} color="blue.500" mt={1} />
                  <Box>
                    <Text
                      fontWeight="bold"
                      color="#000f40"
                      sx={{ fontFamily: "'Montserrat', sans-serif" }}
                    >
                      {value.title}
                    </Text>
                    <Text
                      fontSize="md"
                      color="gray.700"
                      sx={{ fontFamily: "'Montserrat', sans-serif" }}
                    >
                      {value.description}
                    </Text>
                  </Box>
                </ListItem>
              ))}
            </List>
          </Section>
        </VStack>
      </Grid>
    </Container>
  );
};

export default MissionVisionValues;
