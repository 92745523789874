// import React from "react";
// import { MDBCard, MDBCardImage, MDBRow, MDBCol } from "mdb-react-ui-kit";
// import { Box, Text, useBreakpointValue } from "@chakra-ui/react";

// const CardIrixPresentation = () => {
//   const paragraphSize = useBreakpointValue({ base: "sm", md: "xl" });
//   const marginSize = useBreakpointValue({ base: "none", md: "10%" });

//   return (
//     <>
//       <Box p={4}>
//         <Box mb={8}>
//           <Box
//             p={4}
//             width={["100%", "58%", "40%"]}
//             mb={8}
//             marginLeft={marginSize}
//             borderWidth="1px"
//             borderRadius="md"
//             borderStyle="solid"
//             borderColor="gray.300"
//             boxShadow="0px -2px 14px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1)"
//             bg="teal.600"
//             color="white"
//           >
//             <Text fontSize={paragraphSize}>
//               Booking Time este o platformă B2B inovatoare, concepută pentru a
//               satisface nevoile agențiilor de turism. Cu Booking Time, ai acces
//               la o gamă vastă de opțiuni pentru bilete de avion și cazări
//               hoteliere, oferite de multipli furnizori de încredere.
//             </Text>
//           </Box>
//         </Box>
//         <Box mb={8}>
//           <Box
//             p={4}
//             width={["100%", "58%", "40%"]}
//             mb={8}
//             ml="auto"
//             borderWidth="1px"
//             borderRadius="md"
//             borderStyle="solid"
//             borderColor="gray.300"
//             boxShadow="0px -2px 14px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1)"
//             bg="teal.600"
//             color="white"
//           >
//             <Text fontSize={paragraphSize}>
//               Indiferent dacă ești în căutarea celor mai bune tarife la bilete
//               de avion sau dorești să găsești cazări hoteliere ideale pentru
//               clienții tăi, Booking Time îți oferă o platformă intuitivă și
//               eficientă pentru a căuta și compara opțiuni din diferite surse. Ai
//               posibilitatea de a explora diverse destinații și de a alege cele
//               mai potrivite variante, adaptate nevoilor și bugetului clienților
//               tăi.
//             </Text>
//           </Box>
//         </Box>
//         <Box mb={8}>
//           <Box
//             p={4}
//             width={["100%", "58%", "40%"]}
//             mb={8}
//             marginLeft={marginSize}
//             borderWidth="1px"
//             borderRadius="md"
//             borderStyle="solid"
//             borderColor="gray.300"
//             boxShadow="0px -2px 14px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1)"
//             bg="teal.600"
//             color="white"
//           >
//             <Text fontSize={paragraphSize}>
//               Cu Booking Time, ai avantajul de a colabora cu o rețea extinsă de
//               furnizori de încredere, ceea ce îți permite să accesezi tarife
//               competitive și să beneficiezi de o varietate de opțiuni. Poți
//               căuta și compara rapid ofertele de la mai mulți furnizori, astfel
//               încât să poți găsi cele mai bune prețuri și disponibilități pentru
//               clienții tăi.
//             </Text>
//           </Box>
//         </Box>
//         <Box mb={8}>
//           <Box
//             p={4}
//             width={["100%", "58%", "40%"]}
//             mb={8}
//             ml="auto"
//             borderWidth="1px"
//             borderRadius="md"
//             borderStyle="solid"
//             borderColor="gray.300"
//             boxShadow="0px -2px 14px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1)"
//             bg="teal.600"
//             color="white"
//           >
//             <Text fontSize={paragraphSize}>
//               Platforma Booking Time este proiectată pentru a-ți oferi o
//               experiență simplă și eficientă. Ai posibilitatea de a gestiona
//               rezervările, de a genera rapoarte, și de a urmări în mod facil
//               toate detaliile călătoriilor. Totodată, beneficiezi de asistență
//               și suport tehnic în cazul în care întâmpini orice dificultăți sau
//               ai întrebări.
//             </Text>
//           </Box>
//         </Box>
//         <Box mb={8}>
//           <Box
//             p={4}
//             width={["100%", "58%", "40%"]}
//             mb={8}
//             marginLeft={marginSize}
//             borderWidth="1px"
//             borderRadius="md"
//             borderStyle="solid"
//             borderColor="gray.300"
//             boxShadow="0px -2px 14px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1)"
//             bg="teal.600"
//             color="white"
//           >
//             <Text fontSize={paragraphSize}>
//               Cu Booking Time, economisești timp și efort în căutarea celor mai
//               bune oferte pentru clienții tăi. Platforma pune la dispoziție
//               instrumente avansate de căutare și filtrare, precum și informații
//               detaliate despre fiecare opțiune disponibilă. Astfel, poți lua
//               decizii informate și să oferi clienților tăi servicii de înaltă
//               calitate.
//             </Text>
//           </Box>
//         </Box>
//       </Box>

//       <MDBRow className="row-cols-1 row-cols-md-2 g-4 w-100 mx-auto">
//         <MDBCol>
//           <MDBCard>
//             <MDBCardImage
//               src="../images/b2b-page/irix.PNG"
//               alt="..."
//               position="top"
//             />
//           </MDBCard>
//         </MDBCol>
//         <MDBCol>
//           <MDBCard>
//             <MDBCardImage
//               src="../images/b2b-page/irix-1.PNG"
//               alt="..."
//               position="top"
//             />
//           </MDBCard>
//         </MDBCol>
//         <MDBCol>
//           <MDBCard>
//             <MDBCardImage
//               src="../images/b2b-page/irix-2.PNG"
//               alt="..."
//               position="top"
//             />
//           </MDBCard>
//         </MDBCol>
//         <MDBCol>
//           <MDBCard>
//             <MDBCardImage
//               src="../images/b2b-page/irix-3.PNG"
//               alt="..."
//               position="top"
//             />
//           </MDBCard>
//         </MDBCol>
//       </MDBRow>
//     </>
//   );
// };

// export default CardIrixPresentation;

import React, { useState } from "react";
import {
  Box,
  Container,
  Text,
  SimpleGrid,
  Image,
  useBreakpointValue,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  VStack,
} from "@chakra-ui/react";
import { Globe, Cpu, Train, Plane } from "lucide-react";

const BookingTimePresentation = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedImage, setSelectedImage] = useState("");
  const paragraphSize = useBreakpointValue({ base: "md", md: "xl" });

  // Colors
  const borderColor = useColorModeValue("gray.200", "gray.600");

  const handleImageClick = (imageSrc) => {
    setSelectedImage(imageSrc);
    onOpen();
  };

  return (
    <Container maxW="8xl" py={10}>
      {/* First Section - Hero */}
      <Box
        w="full"
        p={8}
        bg="blue.500"
        color="white"
        borderRadius="xl"
        position="relative"
        overflow="hidden"
        mb="20px"
      >
        <Box position="absolute" right="-10" top="-10" opacity={0.1}>
          <Globe Cpu size={200} />
        </Box>
        <VStack
          spacing={4}
          position="relative"
          align="center"
          maxW="800px"
          mx="auto"
        >
          <Text fontSize={paragraphSize} fontFamily="Montserat">
            Booking Time is an innovative B2B platform designed to meet the
            needs of travel agencies. With Booking Time, you gain access to a
            wide range of flight tickets and hotel accommodations offered by
            multiple trusted providers.
          </Text>
        </VStack>
      </Box>

      {/* Features Grid */}
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5} mb={5}>
        <Box
          w="full"
          p={8}
          bg="blue.500"
          color="white"
          borderRadius="xl"
          position="relative"
          overflow="hidden"
          mb="5px"
        >
          <Box position="absolute" right="-10" top="-10" opacity={0.1}>
            <Plane size={200} />
          </Box>
          <VStack
            spacing={4}
            position="relative"
            align="center"
            maxW="800px"
            mx="auto"
          >
            <Text fontSize={paragraphSize} fontFamily="Montserat">
              Whether you're looking for the best airfare rates or the perfect
              hotel accommodations for your clients, Booking Time provides an
              intuitive and efficient platform to search and compare options
              from various sources. You can explore different destinations and
              choose the most suitable options tailored to your clients' needs
              and budget.
            </Text>
          </VStack>
        </Box>
        <Box
          w="full"
          p={8}
          bg="blue.500"
          color="white"
          borderRadius="xl"
          position="relative"
          overflow="hidden"
          mb="5px"
        >
          <Box position="absolute" right="-10" top="-10" opacity={0.1}>
            <Train size={200} />
          </Box>
          <VStack
            spacing={4}
            position="relative"
            align="center"
            maxW="800px"
            mx="auto"
          >
            <Text fontSize={paragraphSize} fontFamily="Montserat">
              The Booking Time platform is designed to provide you with a simple
              and efficient experience. You can manage bookings, generate
              reports, and easily track all travel details. Additionally, you
              benefit from assistance and technical support in case you
              encounter any difficulties or have questions.
            </Text>
          </VStack>
        </Box>
      </SimpleGrid>

      {/* Benefits Section */}
      <Box
        w="full"
        p={8}
        bg="blue.500"
        color="white"
        borderRadius="xl"
        position="relative"
        overflow="hidden"
        mb="5px"
      >
        <Box position="absolute" right="-10" top="-10" opacity={0.1}>
          <Cpu size={200} />
        </Box>
        <VStack
          spacing={4}
          position="relative"
          align="center"
          maxW="800px"
          mx="auto"
        >
          <Text fontSize={paragraphSize} fontFamily="Montserat">
            With Booking Time, you save time and effort in finding the best
            deals for your clients. The platform provides advanced search and
            filtering tools, along with detailed information about each
            available option. This allows you to make informed decisions and
            offer your clients high-quality services.
          </Text>
        </VStack>
      </Box>

      {/* Image Gallery */}
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={6}>
        {[
          "../images/b2b-page/irix.PNG",
          "../images/b2b-page/irix-1.PNG",
          "../images/b2b-page/irix-2.PNG",
          "../images/b2b-page/irix-3.PNG",
        ].map((image, index) => (
          <Box
            key={index}
            cursor="pointer"
            borderRadius="xl"
            overflow="hidden"
            borderWidth="1px"
            borderColor={borderColor}
            boxShadow="lg"
            transition="all 0.3s"
            _hover={{
              transform: "scale(1.02)",
              boxShadow: "xl",
            }}
            onClick={() => handleImageClick(image)}
          >
            <Image
              src={image}
              alt={`Booking Time Interface ${index + 1}`}
              w="full"
              h="auto"
              objectFit="cover"
              loading="lazy"
            />
          </Box>
        ))}
      </SimpleGrid>

      {/* Fullscreen Image Modal */}
      <Modal isOpen={isOpen} onClose={onClose} size="full">
        <ModalOverlay bg="blackAlpha.900" />
        <ModalContent bg="transparent" boxShadow="none">
          <ModalCloseButton
            color="white"
            size="lg"
            p={2}
            _hover={{ bg: "whiteAlpha.300" }}
          />
          <ModalBody
            display="flex"
            alignItems="center"
            justifyContent="center"
            p={0}
          >
            <Image
              src={selectedImage}
              alt="Fullscreen view"
              maxH="90vh"
              objectFit="contain"
              onClick={onClose}
              cursor="pointer"
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Container>
  );
};

export default BookingTimePresentation;
