import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Container,
  Heading,
  Text,
  Flex,
  Image,
  Button,
  useBreakpointValue,
  VStack,
  HStack,
  Circle,
  Badge,
  useColorModeValue,
  Icon,
  SimpleGrid,
} from "@chakra-ui/react";
import { MdLocalHotel, MdArrowForward } from "react-icons/md";
import { Link } from "react-router-dom";

const categories = [
  {
    icon: MdLocalHotel,
    title: "Expedia",
    text: "is a global leader in providing hotel accommodation solutions, offering access to an extensive range of hotels and other options. Through the integration of this top provider, we are able to offer an enormous inventory of accommodation options worldwide, from guesthouses and apartments to luxury hotels, at the most competitive rates.",
    logos: ["/images/furnizori/Expedia-Logo.jpg"],
    stats: ["600K+ Hotels", "Global Coverage", "Best Rates"],
  },
  {
    icon: MdLocalHotel,
    title: "HotelBeds",
    text: "is a renowned hotel accommodation provider with a long tradition and excellent quality in the B2B service area. Through this provider, we offer our partners over 170,000 accommodation units worldwide, at the most competitive prices. ",
    logos: ["/images/furnizori/Hotelbeds_Logo_full.png"],
    stats: ["24/7 Support", "180K+ Units", "B2B Expert"],
  },
  {
    icon: MdLocalHotel,
    title: "Hotelcon",
    text: "is a well-known, reliable accommodation provider in the local market, established through the quality of its service and the diversity of its domestic and worldwide offerings at very good rates.",
    logos: ["/images/furnizori/Hotelcon NewER Logo.png"],
    stats: ["Local Expert", "Premium Service", "Competitive Rates"],
  },
  {
    icon: MdLocalHotel,
    title: "Partner Network",
    text: "A whole series of other traditional providers are integrated into our platform to ensure a good pricing dynamic and a wide variety of hotels or accommodation units to cover all the needs of our partners. Among them are: Restel, Stuba, TBO, Travco, W2m.",
    logos: [
      "/images/furnizori/Restel Logo.jpg",
      "/images/furnizori/Stuba-Logo.jpg",
      "/images/furnizori/TBO LOGO.svg",
      "/images/furnizori/LOGO-W2M.webp",
      "/images/furnizori/travco logo.png",
    ],
    stats: ["5+ Partners", "Global Network", "Dynamic Pricing"],
  },
];

const CategoryCard = ({ icon, title, text, logos, stats }) => {
  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.700");

  return (
    <Box
      position="relative"
      bg={bgColor}
      p={8}
      borderRadius="2xl"
      border="1px"
      borderColor={borderColor}
      _hover={{
        transform: "translateY(-4px)",
        shadow: "xl",
        transition: "all 0.3s ease",
      }}
      overflow="hidden"
      minHeight="400px" // Add a minimum height to ensure consistent card sizes
    >
      {/* Decorative Elements */}
      <Circle
        size="200px"
        position="absolute"
        top="-100px"
        right="-100px"
        bg="blue.50"
        opacity={0.3}
      />

      {/* Main content container */}
      <Box position="relative" height="100%">
        {/* Header section */}
        <HStack spacing={4} mb={6}>
          <Circle size="40px" bg="blue.50">
            <Icon as={icon} w={5} h={5} color="blue.500" />
          </Circle>
          <Heading size="md" fontWeight="700">
            {title}
          </Heading>
        </HStack>

        {/* Text content */}
        <Text color="gray.600" fontSize="md" mb={6}>
          {text}
        </Text>

        {/* Logos section */}
        <Flex wrap="wrap" gap={4} justify="center" mb={16}>
          {logos.map((logo, index) => (
            <Image
              key={index}
              src={logo}
              alt={`${title} logo`}
              maxW="150px"
              maxH="90px"
              mx="auto"
              objectFit="contain"
              filter="grayscale(0.3)"
              _hover={{ filter: "grayscale(0)", transform: "scale(1.05)" }}
              transition="all 0.3s ease"
            />
          ))}
        </Flex>

        {/* Stats badges - positioned absolutely at bottom */}
        <Box position="absolute" bottom={0} left={0} right={0} pb={4}>
          <HStack spacing={4} justify="center" flexWrap="wrap">
            {stats.map((stat, index) => (
              <Badge
                key={index}
                px={3}
                py={1}
                borderRadius="full"
                colorScheme="blue"
                variant="subtle"
                fontSize="sm"
              >
                {stat}
              </Badge>
            ))}
          </HStack>
        </Box>
      </Box>
    </Box>
  );
};

const Hotels = () => {
  const navigate = useNavigate();
  const columns = useBreakpointValue({ base: 1, md: 2 });

  const handleDemoRequest = () => {
    window.scrollTo(0, 0);
    navigate("/contact");
  };

  return (
    <Box py={20} bg="gray.50">
      <Container maxW="container.xl">
        <VStack spacing={16}>
          <VStack spacing={4} textAlign="center">
            <Heading
              size="2xl"
              bgGradient="linear(to-r, blue.400, blue.600)"
              bgClip="text"
              letterSpacing="tight"
            >
              Hotel Services
            </Heading>
            <Text fontSize="xl" color="gray.600" maxW="container.md">
              Connect with world-class hotel providers and unlock access to
              millions of properties worldwide
            </Text>
          </VStack>

          <SimpleGrid columns={columns} spacing={8} w="full">
            {categories.map((category, index) => (
              <CategoryCard key={index} {...category} />
            ))}
          </SimpleGrid>

          <Button
            size="lg"
            colorScheme="blue"
            rightIcon={<MdArrowForward />}
            onClick={handleDemoRequest}
            borderRadius="full"
            px={8}
            _hover={{
              transform: "translateY(-2px)",
              shadow: "lg",
            }}
          >
            Request Demo Account
          </Button>
        </VStack>
      </Container>
    </Box>
  );
};

export default Hotels;

// import React from "react";
// import { useNavigate } from "react-router-dom";
// import {
//   Box,
//   Container,
//   Heading,
//   Text,
//   Flex,
//   Image,
//   Button,
//   VStack,
//   HStack,
//   Circle,
//   Badge,
//   useColorModeValue,
//   Icon,
//   SimpleGrid,
//   useBreakpointValue,
// } from "@chakra-ui/react";
// import { MdLocalHotel, MdArrowForward } from "react-icons/md";
// import { GiAirplaneDeparture } from "react-icons/gi";
// import { Link } from "react-router-dom";

// const categories = [
//   {
//     icon: MdLocalHotel,
//     title: "Expedia",
//     text: "is a global leader in providing hotel accommodation solutions, offering access to an extensive range of hotels and other options. Through the integration of this top provider, we are able to offer an enormous inventory of accommodation options worldwide, from guesthouses and apartments to luxury hotels, at the most competitive rates.",
//     logos: ["/images/furnizori/Expedia-Logo.jpg"],
//     stats: ["170K+ Hotels", "Global Coverage", "Best Rates"],
//   },
//   {
//     icon: MdLocalHotel,
//     title: "HotelBeds",
//     text: "is a renowned hotel accommodation provider with a long tradition and excellent quality in the B2B service area. Through this provider, we offer our partners over 170,000 accommodation units worldwide, at the most competitive prices. ",
//     logos: ["/images/furnizori/Hotelbeds_Logo_full.png"],
//     stats: ["24/7 Support", "170K+ Units", "B2B Expert"],
//   },
//   {
//     icon: MdLocalHotel,
//     title: "Hotelcon",
//     text: "is a well-known, reliable accommodation provider in the local market, established through the quality of its service and the diversity of its domestic and worldwide offerings at very good rates.",
//     logos: ["/images/furnizori/Hotelcon NewER Logo.png"],
//     stats: ["Local Expert", "Premium Service", "Competitive Rates"],
//   },
//   {
//     icon: MdLocalHotel,
//     title: "Partner Network",
//     text: "A whole series of other traditional providers are integrated into our platform to ensure a good pricing dynamic and a wide variety of hotels or accommodation units to cover all the needs of our partners. Among them are: Restel, Stuba, TBO, Travco, W2m.",
//     logos: [
//       "/images/furnizori/Restel Logo.jpg",
//       "/images/furnizori/Stuba-Logo.jpg",
//       "/images/furnizori/TBO LOGO.svg",
//       "/images/furnizori/LOGO-W2M.webp",
//       "/images/furnizori/travco logo.png",
//     ],
//     stats: ["5+ Partners", "Global Network", "Dynamic Pricing"],
//   },
// ];

// const ServiceCard = ({ icon, title, text, logos, stats }) => {
//   const bgColor = useColorModeValue("white", "gray.800");
//   const borderColor = useColorModeValue("gray.200", "gray.700");

//   return (
//     <Box
//       position="relative"
//       bg={bgColor}
//       p={8}
//       borderRadius="2xl"
//       border="1px"
//       borderColor={borderColor}
//       _hover={{
//         transform: "translateY(-4px)",
//         shadow: "xl",
//         transition: "all 0.3s ease",
//       }}
//       overflow="hidden"
//       height="full"
//     >
//       {/* Decorative Elements */}
//       <Circle
//         size="200px"
//         position="absolute"
//         top="-100px"
//         right="-100px"
//         bg="blue.50"
//         opacity={0.3}
//       />

//       <Flex direction="column" height="full">
//         <VStack align="stretch" spacing={6} flex="1">
//           <HStack spacing={4}>
//             <Circle size="40px" bg="blue.50">
//               <Icon as={icon} w={5} h={5} color="blue.500" />
//             </Circle>
//             <Heading size="md" fontWeight="700">
//               {title}
//             </Heading>
//           </HStack>

//           <Box position="relative">
//             {Array.isArray(logos) ? (
//               <Flex
//                 wrap="wrap"
//                 justify="center"
//                 align="center"
//                 gap={4}
//                 maxW="400px"
//                 mx="auto"
//               >
//                 {logos.map((logo, index) => (
//                   <Box
//                     key={index}
//                     display="flex"
//                     justifyContent="center"
//                     alignItems="center"
//                   >
//                     <Image
//                       src={logo}
//                       alt={`${title} partner logo ${index + 1}`}
//                       maxW="100px"
//                       maxH="60px"
//                       objectFit="contain"
//                       filter="grayscale(0.3)"
//                       _hover={{
//                         filter: "grayscale(0)",
//                         transform: "scale(1.05)",
//                       }}
//                       transition="all 0.3s ease"
//                     />
//                   </Box>
//                 ))}
//               </Flex>
//             ) : (
//               <Image
//                 src={logos}
//                 alt={`${title} logo`}
//                 maxW="150px"
//                 maxH="90px"
//                 objectFit="contain"
//                 mx="auto"
//                 filter="grayscale(0.3)"
//                 _hover={{ filter: "grayscale(0)", transform: "scale(1.05)" }}
//                 transition="all 0.3s ease"
//               />
//             )}
//           </Box>

//           <Text color="gray.600" fontSize="md">
//             {text}
//           </Text>
//         </VStack>

//         <HStack spacing={4} justify="center" flexWrap="wrap" mt={6}>
//           {stats.map((stat, index) => (
//             <Badge
//               key={index}
//               px={3}
//               py={1}
//               borderRadius="full"
//               colorScheme="blue"
//               variant="subtle"
//               fontSize="sm"
//             >
//               {stat}
//             </Badge>
//           ))}
//         </HStack>
//       </Flex>
//     </Box>
//   );
// };
// const Hotels = () => {
//   const navigate = useNavigate();
//   const columns = useBreakpointValue({ base: 1, lg: 2 });

//   const handleDemoRequest = () => {
//     window.scrollTo(0, 0);
//     navigate("/contact");
//   };

//   return (
//     <Box py={20} bg="gray.50" id="ticketing">
//       <Container maxW="container.xl">
//         <VStack spacing={16}>
//           <VStack spacing={4} textAlign="center">
//             <Heading
//               size="2xl"
//               bgGradient="linear(to-r, blue.400, blue.600)"
//               bgClip="text"
//               letterSpacing="tight"
//             >
//               Air Services
//             </Heading>
//             <Text fontSize="xl" color="gray.600" maxW="container.md">
//               Connect with leading airline providers and access comprehensive
//               flight inventory worldwide
//             </Text>
//           </VStack>

//           <SimpleGrid columns={columns} spacing={8} w="full">
//             {categories.map((category, index) => (
//               <ServiceCard key={index} {...category} />
//             ))}
//           </SimpleGrid>

//           <Button
//             size="lg"
//             colorScheme="blue"
//             rightIcon={<MdArrowForward />}
//             onClick={handleDemoRequest}
//             borderRadius="full"
//             px={8}
//             _hover={{
//               transform: "translateY(-2px)",
//               shadow: "lg",
//             }}
//           >
//             Request Demo Account
//           </Button>
//         </VStack>
//       </Container>
//     </Box>
//   );
// };

// export default Hotels;
